/* eslint-disable no-undef */

import TimeMetrics from './TimeMetrics.js';

function SearchApp() {
  let platform = window.amp.platform;
  let track = platform.track;
  TimeMetrics(window.amp.platform);
  let stemmer = require('stemmer');
  let urlHostPage = '';
  let base = {};
  
  let options = {
    sp_queries: {},
    urlHostPage: urlHostPage
  };
  const numResultsPerPage = 10;
  let urlParams = getUrlParams(window.location.search);
  /**
   * grabs all url parameters immediately on page load
   * and creates an object with param properties.
   * also leaves the function available for later use if needed.
   */
  function getUrlParams(search) {
    search = search || window.location.search;

    let params = {};
    let paramsArray = search.split("&");

    if (paramsArray.length > 0) {
      paramsArray.forEach(function (elem, idx) {
        elem = elem.replace("?", "");
        let elemArray = elem.split("=");
        params[elemArray[0]] = elemArray[1] || 0;
      });
    }

    track.info("urlParams: " + JSON.stringify(params));

    return params;
  }

  let srMetrics = new window.amp.platform.TimeMetrics("SR Timing Metrics");

  let component = {
    init: function init(userOptions, callback) {
      srMetrics.createBrowserPerformanceRun();
      srMetrics.getReport("#perf-report");

      if (typeof userOptions === "object") $.extend(options, userOptions);
      let spqs = options.sp_queries;
      let searchQuery = urlParams.sp_q || false;

      track.info("Search: urlParams: " + JSON.stringify(urlParams));

      if (typeof searchQuery === "string" && searchQuery.length > 0) {
        spqs.spq = "?sp_q=" + searchQuery;
        spqs.spq1 = typeof urlParams.sp_q_1 === "string" ? urlParams.sp_q_1 : "";
        spqs.spn = typeof urlParams.sp_n === "string" ? urlParams.sp_n : 0;

        srMetrics.recordTimeStamp("start");
        runWatsonSearch(searchQuery, spqs.spq1, spqs.spn, callback);
      } else {
        track.info("Search: no search term provided");
      }
    },
  };

  function postData (args) {
  return fetch(args.url, {
      method: 'POST',
      headers: args.headers,
      body: args.data
    })
      .then(
        (response) => {
          return response.json()
        }
      )
  
  }
  function runWatsonSearch(searchQuery, category, spn, callback) {
    let headers = {
      'Content-Type': 'application/json',
    }
   
    let data= {
        'natural_language_query': searchQuery,
        'offset': parseInt(spn),
        "xReturn": ["document_id", "title", "extracted_metadata.title", "extracted_metadata.subject",
        "extracted_metadata.file_type", "extracted_metadata.filename", 
        "url", "metadata.source.url", "enriched_html"]
    }
    if (category) data.filter = "enriched_html.entities.text::\"" + decodeURIComponent(category) + "\",enriched_html.entities.model_name::Category"
    const args = {
      headers: headers,
      data: JSON.stringify(data),
      url: '/api/ampf/search'
    }
    postData(args)
    .then(
      (response) => {
        if (response) {
          watsonSearchSuccess(searchQuery, category, spn, response, callback) 
        }
      }
    )
    .catch(
      (error) => {
        base.error = "Error occurred calling Search service";

        callback(base);
        return params.callback(error, error.message)
      }
    )

  }


  function convertWexResultList(resultList) {
    let newResultList = [];
    let indexCount = 0;

    resultList.forEach((document) => {
      let newDocument = {};
      newDocument.index = indexCount++;
      newDocument.url = document._attributes.url;
      document.content.forEach((content) => {
        const propertyName = content._attributes.name;
        if (!content.hasOwnProperty("_text")) {
          newDocument[propertyName] = "";
        } else {
          newDocument[propertyName] = content._text;
        }
      });
      newResultList.push(newDocument);
    });
    return newResultList;
  }

  /* take a given single string set of query terms and breaks into
   *   a string of only essential individual terms for highlighting delimited by | and
   *   surrounded by (). returns empty string if no terms */
  function getQueryHighlightTerms(query) {
    if (!query || typeof query !== "string") {
      return {};
    }

    var ExcludePhrase = /-&quot;.+?&quot;/g;
    var QT = /&quot;/g;
    var PLUS = /\+/g;
    var SPC = /\s{2,}/g;
    var MINUS = /^-/;

    /* remove excluded phrases, +'s, quotes and multi-spaces */
    var termsString = query
      .replace(ExcludePhrase, "")
      .replace(PLUS, "")
      .replace(QT, "")
      .replace(/\*/gi, "")
      .trim()
      .replace(SPC, " ")
      .replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&") // modified from proposed ES7 regexp escape function
      .split(" ")
      .filter(function (term) {
        return !MINUS.test(term);
      })
      .map(function (term) {
        return stemmer(term);
      })
      .join("|");

    track.info("search: getHighlightTerms: termsString: " + termsString);

    if (termsString) {
      return "(" + termsString + ")";
    }

    return termsString;
  }

  function getWexPaginationData(searchResults, currentSearchUrl) {
    let pageLinksMax = 9; // should be an odd number to center current page in page links
    let pageLinksMovingMargin = Math.floor(pageLinksMax / 2);
    let pages = [];
    let previous = {
      pageStartItemIndex: 0,
    };
    let next = {
      pageStartItemIndex: 0,
    };
    let last = {};
    let sr = searchResults;

    let ttlPages = Math.ceil(sr.resultsTtl / numResultsPerPage);
    let currentStartIndex = sr.resultsLower;
    let currentPageIndex = Math.ceil(currentStartIndex / numResultsPerPage);

    let startPageIndex = Math.max(
      1,
      Math.min(
        currentPageIndex - pageLinksMovingMargin,
        ttlPages - pageLinksMax + 1
      )
    );
    let endPageIndex = Math.min(startPageIndex + pageLinksMax - 1, ttlPages);

    track.info(
      "Search: getPaginationData: startPageIndex - endPageIndex: " +
        startPageIndex +
        "-" +
        endPageIndex
    );

    for (let p = startPageIndex; p <= endPageIndex; p++) {
      pages.push(getPage(p, numResultsPerPage, currentStartIndex));

      if (p === ttlPages) {
        last.pageStartItemIndex = pages[pages.length - 1].pageStartItemIndex;
      }
    }

    function getPage(pageIndex, numResultsPerPage, currentStartIndex) {
      let pageNumber = pageIndex;
      let pageStartItemIndex = (pageIndex - 1) * numResultsPerPage + 1;

      return {
        pageNumber: pageNumber,
        pageStartItemIndex: pageStartItemIndex - 1,
        currentPageClass:
          currentStartIndex === pageStartItemIndex ? "current-page" : "",
      };
    }

    if (sr.resultsPagePrev > 0) {
      previous.pageStartItemIndex = sr.resultsLower - numResultsPerPage;
    }

    if (sr.resultsPageNext > 0) {
      next.pageStartItemIndex = sr.resultsLower + numResultsPerPage - 1;
    }

    let paginationData = {
      urlHostPage: options.urlHostPage,
      currentSearchUrl: currentSearchUrl,
      first: { pageStartItemIndex: 1 },
      previous: previous,
      next: next,
      last: last,
      pages: pages,
      ttlPages: ttlPages,
      resultsPerPage: numResultsPerPage,
      currentStartIndex: currentStartIndex,
      currentPageIndex: currentPageIndex,
      startPageIndex: startPageIndex,
      endPageIndex: endPageIndex,
    };

    return paginationData;
  }


  function watsonSearchSuccess(searchQuery, category, spn, response, callback) {
    srMetrics.recordTimeStamp("rcv resp");
    let startOffset = parseInt(spn);
    let searchScrubbedChars = urlParams.search_scrubbed || "";
    let numTotalResults = 0;
    let baseData = {};
    let resultList;
    resultList = response.results
    numTotalResults = response.matchingResults;
    
    if (resultList !== undefined) baseData.searchResults = resultList;

    base.urlHostPage = '';
    base.normalizedUserQuery = urlParams.sp_q;
    base.searchScrubbed = searchScrubbedChars.length > 0 ? decodeURIComponent(searchScrubbedChars) : "";
    baseData.userQuery = searchQuery.replace(/%20/g, '+');
    base.urlQuery = "?sp_q=" + baseData.userQuery + '&sp_q_1=' + category.replace(/%20/g, '+');
    baseData.userQuery = baseData.userQuery.replace(/\+/g, " ");
    baseData.userQuery.replace(/&amp;/g, "&").replace(/&quot;/g, '"');
    baseData.resultsTtl = numTotalResults;
    baseData.resultsLower = startOffset + 1;
    baseData.resultsUpper = startOffset + numResultsPerPage;
    baseData.resultsPage = startOffset + numResultsPerPage;
    baseData.resultsPagePrev = startOffset - numResultsPerPage <= 0 ? 1 : startOffset - numResultsPerPage;
    baseData.resultsPageNext = startOffset + numResultsPerPage >= numTotalResults ? 0 : startOffset + numResultsPerPage;
    base.data = baseData;
    base.paginationData = getWatsonPaginationData(base.data, base.urlQuery);
    base.queryTermsRegExp = getQueryHighlightTerms(baseData.userQuery);

    srMetrics.recordTimeStamp("rendered");
    srMetrics.getReport("#perf-report");
    track.info("BASE : " + JSON.stringify(base));

    callback(base);
  }

  // getWatsonPaginationData
  // based on total number of results
  // calculate how many pages to display up to max(default of 9), 
  // based on current result number calculate start and end pages
  
  function getWatsonPaginationData(searchResults, currentSearchUrl) {
    let pageLinksMax = 9; // should be an odd number to center current page in page links
    let pageLinksMovingMargin = Math.floor(pageLinksMax / 2);
    let pages = [];
    let previous = {
      pageStartItemIndex: 0,
    };
    let next = {
      pageStartItemIndex: 0,
    };
    let last = {};
    let sr = searchResults;

    let ttlPages = Math.ceil(sr.resultsTtl / numResultsPerPage);
    let currentStartIndex = sr.resultsLower;
    let currentPageIndex = Math.ceil(currentStartIndex / numResultsPerPage);

    let startPageIndex = Math.max(
      1,
      Math.min(
        currentPageIndex - pageLinksMovingMargin,
        ttlPages - pageLinksMax + 1
      )
    );
    let endPageIndex = Math.min(startPageIndex + pageLinksMax - 1, ttlPages);

    track.info(
      "Search: getPaginationData: startPageIndex - endPageIndex: " +
        startPageIndex +
        "-" +
        endPageIndex
    );

    for (let p = startPageIndex; p <= endPageIndex; p++) {
      pages.push(getPage(p, numResultsPerPage, currentStartIndex));

      if (p === ttlPages) {
        last.pageStartItemIndex = pages[pages.length - 1].pageStartItemIndex;
      }
    }

    function getPage(pageIndex, numResultsPerPage, currentStartIndex) {
      let pageNumber = pageIndex;
      let pageStartItemIndex = (pageIndex - 1) * numResultsPerPage + 1;

      return {
        pageNumber: pageNumber,
        pageStartItemIndex: pageStartItemIndex - 1,
        currentPageClass:
          currentStartIndex === pageStartItemIndex ? "current-page" : "",
      };
    }

    if (sr.resultsPagePrev > 0) {
      previous.pageStartItemIndex = sr.resultsLower - numResultsPerPage;
    }

    if (sr.resultsPageNext > 0) {
      next.pageStartItemIndex = sr.resultsLower + numResultsPerPage - 1;
    }

    let paginationData = {
      urlHostPage: options.urlHostPage,
      currentSearchUrl: currentSearchUrl,
      first: { pageStartItemIndex: 1 },
      previous: previous,
      next: next,
      last: last,
      pages: pages,
      ttlPages: ttlPages,
      resultsPerPage: numResultsPerPage,
      currentStartIndex: currentStartIndex,
      currentPageIndex: currentPageIndex,
      startPageIndex: startPageIndex,
      endPageIndex: endPageIndex,
    };

    return paginationData;
  }


  return component;
}

export default SearchApp;




