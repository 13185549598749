import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/*
 *  Generic Components
 */
import Input from '../views/Input'
import SuccessMessage from '../views/SuccessMessage'
import { ErrorMessageComponent, PleaseNoteComponent, SendEmailButtonComponent } from '../views/StatelessComponent'

/*
 * Helper functions
 */
import { formSelectedData, CONSTs } from '../views/utils'

/*
 * Tax and Statement Form component
 */
export default class TaxAndStatement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showServiceFailedMessage: false,
      showSuccessMessage: false,
      loading: false,
      formData: {
        packageid: null,
        documentid: null
      },
      errorFields: {
        packageid: false,
        documentid: false
      }
    }

    /*
     * React refs to access the component
     */
    this.packageidRef = React.createRef()
    this.documentidRef = React.createRef()

    /*
     * Binding this(current object) keyword with the methods
     */
    this.submitForm = this.submitData.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
    this.showServerResponse = this.showServerResponse.bind(this)
  }

  /*
   * Show service response
   */
  showServerResponse (props, message) {
    if (props && message) {
      if (message === CONSTs.SUCCESS) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: true,
            showServiceFailedMessage: false
          }
        )
      } else if (message === CONSTs.FAILURE) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      } else {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      }
    }

    return true
  }

  /*
   * Form status: valid or invalid
   */
  isFormValid () {
    const {
      formData,
      errorFields
    } = this.state

    this.validateField('packageid', formData.packageid, 2)
    this.validateField('documentid', formData.documentid, 2)

    return !errorFields.packageid && !errorFields.documentid
  }

  /*
   * Render Buttons
   */
  renderSubmitButton () {
    const {
      props: {
        content: {
          formLabels: {
            buttonlabel,
            buttonLoadingLabel
          }
        }
      },
      state: {
        loading
      }
    } = this

    return (
      <SendEmailButtonComponent
        callback={this.submitForm}
        isLoading={loading}
        defaultLabel={buttonlabel}
        loadingLabel={buttonLoadingLabel}
        dataAnalytics={CONSTs.TAXDOWNLOADFORMBTN}
      />
    )
  }

  /*
   * Validate the form fields
   */
  validateField (fieldName, value, type) {
    if (fieldName) {
      let fieldValues = this.state.formData
      let fieldErrors = this.state.errorFields

      switch (fieldName) {
      case 'packageid':
        fieldValues.packageid = value

        if (value) {
          const packageidValid = value.length > 3

          if (type === 1) {
            if (fieldErrors.packageid) {
              fieldErrors.packageid = !packageidValid
            }
          } else {
            fieldErrors.packageid = !packageidValid
          }
        } else {
          fieldErrors.packageid = type === 2
        }

        break
      case 'documentid':
        fieldValues.documentid = value

        if (value) {
          const documentidValid = value.length > 3

          if (type === 1) {
            if (fieldErrors.documentid) {
              fieldErrors.documentid = !documentidValid
            }
          } else {
            fieldErrors.documentid = !documentidValid
          }
        } else {
          fieldErrors.documentid = type === 2
        }

        break
      default:
        fieldValues[fieldName] = value

        break
      }

      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
  }

  handleFieldChange (event, type) {
    event.preventDefault()

    const name = event.target.name
    const value = event.target.value

    this.validateField(name, value, type)
  }

  /*
   * Form Submission
   */
  submitData (event) {
    event.preventDefault()

    const {
      props: { callback, content: { formEmailInfo, formTemplate } },
      state: { formData }
    } = this

    const isFormValid = this.isFormValid()

    if (isFormValid === true) {
      this.setState({ loading: true })

      const items = formSelectedData(formData, formTemplate)

      if (callback && typeof callback === 'function' && items) {
        return callback(items, formEmailInfo, this.showServerResponse)
      }

      return true
    }

    return false
  }

  render () {
    const {
      props: {
        name,
        content: {
          formErrorMessages,
          formPleaseNote,
          serviceMessages,
          formLabels
        }
      },
      state: {
        showServiceFailedMessage,
        showSuccessMessage,
        errorFields
      }
    } = this

    return (
      <Fragment>
        {
          showSuccessMessage &&
          serviceMessages &&
          serviceMessages.serviceSuccess &&
          <SuccessMessage
            thankYou={serviceMessages.serviceSuccessThankyou}
            message={serviceMessages.serviceSuccess}
            dataAnalytics={CONSTs.ANALYTICSSUCCESSMSG}
          />
        }
        {
          !showSuccessMessage &&
          <div className='u-sizeConstrained'>
            <form
              name={name}
              className='u-marginBottom5gu u-size5of12 u-md-size8of12 u-sm-sizeFull'
              onBlur={(e) => { this.handleFieldChange(e) }}
              onKeyUp={(e) => { this.handleFieldChange(e, 1) }}
            >
              <Input
                name='packageid'
                id='packageid'
                placeholder=''
                label={formLabels.packageid}
                reference={this.packageidRef}
                error={errorFields.packageid}
                message={formErrorMessages.errorPackageid}
              />
              <Input
                name='documentid'
                id='documentid'
                placeholder=''
                label={formLabels.documentid}
                reference={this.documentidRef}
                error={errorFields.documentid}
                message={formErrorMessages.errorDocumentid}
              />
              {
                showServiceFailedMessage &&
                serviceMessages.serviceFailed &&
                <ErrorMessageComponent
                  message={serviceMessages.serviceFailed}
                  dataAnalytics={CONSTs.ANALYTICSFAILURESMSG}
                />
              }
              {
                this.renderSubmitButton()
              }
            </form>
            {
              formPleaseNote &&
              formPleaseNote.message &&
                <PleaseNoteComponent
                  content={formPleaseNote.message}
                />
            }
          </div>

        }
      </Fragment>
    )
  }
}

/*
 * Tax and Statement Form default Proptypes
 */
const propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.object
}

TaxAndStatement.propTypes = propTypes
