import React from 'react'
import classNames from 'classnames'
import { format } from '@cwp/utils'
import { parseValue } from '../views/utils'

export const StockQuoteData = (prcChg, lstTrdPrc, prevPriceLabel, unChgLabel, open) => {
  const ADEL_DEFAULT_CLASS = 'Table-cell'
  let displayArrow = ''
  let textColorClasses = ''
  let lastTradePrice = ''
  let priceChange = ''
  let previousPrcChgCode = ''

  if (parseFloat(prcChg) > 0) {
    displayArrow = <span className='Icon--before u-textColorConfirm' data-icon='&#xe5d9;' />
    textColorClasses = classNames(ADEL_DEFAULT_CLASS, 'u-textColorConfirm')
    lastTradePrice = format.addThousandsSeparator(parseValue(lstTrdPrc))
    priceChange = <span className="js-price-change">{'+' + format.addThousandsSeparator(parseValue(prcChg))}</span>
  } else if (parseFloat(prcChg) < 0) {
    displayArrow = <span className='Icon--before u-textColorAlert' data-icon='&#xe5db;' />
    textColorClasses = classNames(ADEL_DEFAULT_CLASS, 'u-textColorAlert')
    lastTradePrice = format.addThousandsSeparator(parseValue(lstTrdPrc))
    priceChange = <span className="js-price-change">{format.addThousandsSeparator(format.fixNegativeSign(parseValue(prcChg)))}</span>
  } else if (parseFloat(prcChg) === 0) {
    // Before the market opens
    if (parseFloat(open) === 0) {
      previousPrcChgCode = <span className="js-pc"><strong>{prevPriceLabel} </strong></span>
    }
    lastTradePrice = format.addThousandsSeparator(parseValue(lstTrdPrc))
    priceChange = <span className="js-price-change"><strong>{unChgLabel}</strong></span>
    textColorClasses = ADEL_DEFAULT_CLASS
  }
  return {
    arrow: displayArrow,
    classes: textColorClasses,
    price: lastTradePrice,
    prcChg: priceChange,
    dCode: previousPrcChgCode
  }
}
