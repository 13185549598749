export const icons = {
  error: '\u{e002}'
}

export const ccpa = {
  clientid: {
    yes: 'Client',
    no: ''
  }
}
