import xss from 'xss'

export function addTableWrappers () {
  document.querySelectorAll('table').forEach(table => {
    let wrapper = document.createElement('div')
    wrapper.classList.add('table-responsive')

    let sanitizedTable = document.createElement('table')

    Array.from(table.attributes).forEach(attr => {
      sanitizedTable.setAttribute(attr.name, attr.value)
    })

    sanitizedTable.innerHTML = xss(table.innerHTML)

    // replace original with sanitized table wrapped in div featuring class table-responsive
    wrapper.appendChild(sanitizedTable)
    table.parentNode.insertBefore(wrapper, table)
    table.parentNode.removeChild(table)
  })
}
