const classes = {
  container: 'Advisor--color Content--reversed',
  padding:
    'u-sizeConstrained u-paddingVert10gu u-sm-paddingVert5gu u-sm-sizeConstrainedFlush',
  grid: 'AdvisorProspect Grid u-padding4gu',
  gridLeft:
    'AdvisorMedia u-size1of3 u-sm-sizeFull u-sm-flexOrderFirst u-sm-paddingLeft0 u-sm-paddingBottom4gu',
  gridRight:
    'u-sm-sizeFull u-size2of3 u-paddingLeft6gu u-sm-paddingLeft0 u-posRelative',
  image: 'Advisor-image u-maxWidth100',
  header: 'u-textSizeMsrp4 u-textBold u-sizeFull u-marginBottom6gu u-textOpen',
  actions:
    'u-textReversed u-flexAuto u-textSizeMsrp1 u-marginBottom6gu u-sm-sizeFull',
  actionsRequest:
    'u-textReversed u-size7of12 u-textSizeMsrp1 u-marginBottom6gu u-sm-sizeFull u-flex',
  disclosure: 'AdvisorProspect-disclosure u-marginLeft5gu u-sm-margin0gu',
  search: 'u-textReversed',
  actionGrid: 'Grid',
  actionList: 'u-sizeFull',
  hr: 'u-margin0',
  textGrid: 'Grid',
  bottomText: 'u-sizeFull'
}

export default classes
