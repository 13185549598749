import React, { Component, Fragment } from 'react'
import classnames from 'classnames'

/*
 *  <TextAreaField
 *  name='inquiry'
 *  id='inquiry'
 *  placeholder='Inquiry (Optional)'
 *  label='Inquiry (Optional)'
 *  reference={this.inquiryRef} //React Refs
 *  error={errorFields.inquiry} //Set error true to display the message
 *  message={formErrorMessages.errorInquiry} //Error message to be passed on to display
 *  />
 */

/*
 * TextArea Component with Error message
 */
// Componet should be in class when we are using Refs
class TextAreaField extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)

    this.dataQa = 'Textarea-' + props.id
  }
  render () {
    const {
      name,
      id,
      placeholder,
      reference,
      error,
      label,
      message,
      row,
      defaultValue,
      maxLength
    } = this.props
    return (
      <Fragment>
        <div className={`Input-group ${!error && 'u-marginBottom5gu'}`}>
          <label className="Input-label" htmlFor={id}>
            {label}
          </label>
          <textarea
            className={classnames('Textarea', {
              'Input--error': error === true
            })}
            name={name}
            data-qa={this.dataQa}
            id={id}
            placeholder={placeholder}
            ref={reference}
            rows={row}
            defaultValue={defaultValue}
            maxLength={maxLength}
          />
          {error && (
            <span
              className="Input-icon Icon"
              dangerouslySetInnerHTML={{ __html: '&#xE002;' }}
            />
          )}
        </div>
        {error && (
          <ul className="Input-errorList u-paddingTop1gu u-marginBottom2gu">
            <li className="Input-errorListItem js-formError">{message}</li>
          </ul>
        )}
      </Fragment>
    )
  }
}

export default TextAreaField
