function TimeMetrics (platform) {
  /* time metrics utility for prod */
  function TimeMetricsProd (processName) {
    function doNothing () {
      return false
    }

    return {
      startNewRun: doNothing,
      createBrowserPerformanceRun: doNothing,
      recordTimeStamp: doNothing,
      getReport: function noReport () {
        window.amp.platform.track.info('tMetrics disabled - no report')

        return false
      }
    }
  }

  /* time metrics utility for lower environments and ops */
  function TimeMetricsLowerEnvironments (processName) {
    var timeRecord = {
      processName: processName || 'TMetrics',
      registered: new Date(),
      runs: []
    }

    var currentRun

    function startNewRun (runName) {
      var dt = new Date()

      timeRecord.runs.unshift({
        runName: runName || 'Run ' + dt.getTime(),
        registered: dt,
        reported: 0,
        timeStamps: []
      })

      currentRun = timeRecord.runs[0]
    }

    function recordTimeStamp (timeStampName, dateObj) {
      var dt = new Date()

      dt = (dateObj && dateObj instanceof Date) ? dateObj : dt

      if (timeRecord.runs.length === 0) {
        startNewRun()
      }

      currentRun.timeStamps.push({
        name: timeStampName || dt.toLocaleTimeString(),
        time: dt
      })

      return dt
    }

    function getReport (reportTarget) {
      /* process runs that haven't been reported, always process last run */
      if (timeRecord.runs.length > 0) {
        var runData = timeRecord.runs
        var runs = runData.length

        for (var r = 0; r < runs; r++) {
          var runRecord = runData[r]
          if (runRecord.reported === 0 || r === runs - 1) {
            processRun(runRecord)
          }
        }
      }

      function processRun (runRecord) {
        var runStamps = runRecord.timeStamps
        var stamps = runStamps.length

        if (stamps > 1) {
          runStamps.sort(function (a, b) {
            return a.time - b.time
          })

          calculateMeasures(runStamps)
          runRecord.reported = 1
        }

        function calculateMeasures (runStamps) {
          var tFirst = runStamps[0].time
          var tLast = runStamps[stamps - 1].time

          for (var s = 0; s < stamps; s++) {
            var stamp = runStamps[s]
            var stampTime = stamp.time
            var prevTime = runStamps[Math.max(0, s - 1)].time

            stamp.interval = miliToSecs(stampTime - prevTime)
            stamp.accumulation = miliToSecs(stampTime - tFirst)
            stamp.decumulation = miliToSecs(tLast - stampTime)
          }
        }

        return runRecord
      }

      return timeRecord
    }

    function createBrowserPerformanceRun (runName) {
      if (window.performance.timing) {
        var pt = window.performance.timing

        startNewRun(runName || 'Initial Page Load Perf Timings')

        recordTimeStamp('navStart', new Date(pt.navigationStart))
        recordTimeStamp('reqStart', new Date(pt.requestStart))
        recordTimeStamp('respStart', new Date(pt.responseStart))
        recordTimeStamp('respEnd', new Date(pt.responseEnd))
        recordTimeStamp('domLoading', new Date(pt.domLoading))
        recordTimeStamp('domComplete', new Date(pt.domComplete))
        recordTimeStamp('loadEnd', new Date(pt.loadEventEnd))
      }
    }

    function miliToSecs (miliSecs) {
      return (miliSecs / 1000).toFixed(3)
    }

    return {
      startNewRun: startNewRun,
      createBrowserPerformanceRun: createBrowserPerformanceRun,
      recordTimeStamp: recordTimeStamp,
      getReport: getReport
    }
  }

  var LOCAL = /127\.0\.0\.1|localhost/

  if (LOCAL.test(window.location.hostname)) {
    platform.TimeMetrics = TimeMetricsLowerEnvironments
  } else {
    platform.TimeMetrics = TimeMetricsProd
  }
}

export default TimeMetrics
