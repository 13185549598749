import React, { Fragment, useEffect, useState } from 'react'
import classes from './Classes'
import LoadMoreButton from './LoadMoreButton'
import Card from './Card'

const CardList = (props) => {
  const [categoryLimit, setCategoryLimits] = useState(+props.cardLimit)
  const [cards, setCards] = useState([])

  useEffect(() => {
    const makeCards = props.cardList.reduce((accumulator, card, index) => {
      if (index < categoryLimit) accumulator.push(<Card key={index} card={card}/>)
      return accumulator
    }, [])
    setCards(makeCards)
  }, [props.cardList, props.category, categoryLimit])

  return (
    <Fragment>
      <div className={classes.cardGrid}>
        <div className={classes.grid}>
          {cards}
        </div>
      </div>
      <div className={classes.countText}>
        {props.cardList.length < categoryLimit ? cards.length : categoryLimit} of {props.cardList.length} articles shown
      </div>
      {
        props.cardList.length > categoryLimit &&
          <LoadMoreButton handleLoadMoreClick={() => setCategoryLimits((prev) => prev + +props.cardLimit)}/>
      }
    </Fragment>
  )
}

export default CardList
