import { postDataToService } from '../common/fetch'
import { CONSTs } from '../components/MarketWatch/views/utils'

export default function getStockQuotes (params) {
  if (!params) {
    return false
  }
  postDataToService(params)
    .then(
      (response) => {
        if (response) {
          const lists = response.d && response.d.identifiers
          const items = lists && lists.results

          if (items) {
            if (lists.results.length === params.length && response.d.explanations === null) {
              return params.callback(items, CONSTs.SUCCESS)
            } else {
              return params.callback(response, response.d.explanations.results)
            }
          } else {
            return params.callback(response, response.error.code)
          }
        } else {
          return params.callback(response, response.error.code)
        }
      }
    )
    .catch(
      (error) => {
        return params.callback(error, error.message)
      }
    )
}
