import React, { useState, useEffect } from 'react'
import './index.css'

const RadioGroup = ({ error, message, name, reference, options, onOptionSelected }) => {
  const [optionChecked, setOptionChecked] = useState(null)
  const handleChange = (event) => {
    setOptionChecked(event.target.value)
  }

  useEffect(() => {
    onOptionSelected(optionChecked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionChecked])

  const currentHideStyle = 'u-invisible'

  return (
    <div className="form">
      <fieldset className="form-group">
        {error && message && <div className={'Input-errorList u-paddingTop1gu u-marginBottom2gu' + (error ? '' : ' ' + currentHideStyle)}>
          <div className="Input-errorListItem js-formError">{message}</div>
        </div>}
        {options.map((option, index) => (
          <div className="Radio" key={`option-${index}`}>
            <input
              className="Radio-button"
              type="radio"
              name={name}
              value={option}
              ref={reference}
              onChange={handleChange}
              checked={optionChecked === option}
            />
            <label className="Radio-label" key={option}>
              {option}
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  )
}

export default RadioGroup
