import React from 'react'
import CardGrid from '../CardGrid/CardGrid'
import CardList from '../CardList/CardList'
import CardCarousel from '../CardCarousel/CardCarousel'
import './index.css'

const CardCollection = props => {
  const { template } = props.content
  switch (template) {
  case 'card-grid-nextGen':
    return <CardGrid {...props.content} />
  case 'card-list-nextGen':
    return <CardList {...props.content} />
  case 'card-carousel-nextGen':
    return <CardCarousel {...props.content} />
  default:
    return null
  }
}
export default CardCollection
