import React from 'react'
import PropTypes from 'prop-types'
/*
 *  Generic Components
 */
import InputField from '../views/Input'
import TextAreaField from '../views/Textarea'
import SuccessModal from '../views/SuccessModal'
import {
  ErrorMessageComponent,
  PleaseNoteComponent,
  SendEmailButtonComponent
} from '../views/StatelessComponent'

/*
 * Helper functions
 */
import { isValidEmail, formatPhoneNumber } from '../../../common/utils'
import { formSelectedData, CONSTs } from '../views/utils'
/*
 * Contact Brokerage Form component
 */

export default class ContactFormIRA extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showServiceFailedMessage: false,
      showSuccessMessage: false,
      loading: false,
      formData: {
        fullname: null,
        emailaddress: null,
        inquiry: null,
        phone: null,
        resultDownload: null
      },
      errorFields: {
        fullname: false,
        emailaddress: false,
        inquiry: false,
        emailWrongFormat: false,
        phone: false
      }
    }
    /*
     * React refs to access the component
     */
    this.fullnameRef = React.createRef()
    this.emailaddressRef = React.createRef()
    this.inquiryRef = React.createRef()
    this.phoneRef = React.createRef()
    /*
     * Binding this(current object) keyword with the methods
     */
    this.submitForm = this.submitData.bind(this)
    this.changeState = this.changeState.bind(this)
    this.getFormStatus = this.getFormStatus.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.renderSubmitButtons = this.renderSubmitButtons.bind(this)
    this.showServerResponse = this.showServerResponse.bind(this)
    this.setEmailErrorMessage = this.setEmailErrorMessage.bind(this)
    this.hide = this.hide.bind(this)

    /* Register events */
    // when advisorSelector event advisorevent occurrs setState to trigger render with advisor name set
    const component = document.querySelector(
      props.content.formTemplate.advisorselector
    )
    component.addEventListener(
      props.content.formTemplate.advisorevent,
      event => {
        this.setState({ advisorName: event.profile.name })
        this.setState({ advisorEmail: event.profile.email })
      }
    )

    const componentResults = document.querySelector(
      props.content.formTemplate.resultsselector
    )
    componentResults.addEventListener(
      props.content.formTemplate.resultsevent,
      event => {
        event.detail.html.then(
          pdfHtml => {
            let fieldValues = this.state.formData
            fieldValues.resultDownload = pdfHtml
            this.setState({ formData: fieldValues })
          },
          function (error) {
            console.error(error)
          }
        )
      }
    )
  }

  hide () {
    document.querySelector(
      this.props.content.formTemplate.modalselector
    ).style.display = 'none'
    this.setState({ showSuccessMessage: false })
  }
  /*
   * Show service response
   */
  showServerResponse (props, message) {
    if (props && message) {
      if (message === CONSTs.SUCCESS) {
        this.setState({
          loading: false,
          showSuccessMessage: true,
          showServiceFailedMessage: false
        })
        document
          .querySelector(this.props.content.formTemplate.modalselector)
          .classList.remove('Modal--fullScreen')
        document
          .querySelector(this.props.content.formTemplate.modalselector)
          .classList.add('Modal--md')
      } else if (message === CONSTs.FAILURE) {
        this.setState({
          loading: false,
          showSuccessMessage: false,
          showServiceFailedMessage: true
        })
      } else {
        this.setState({
          loading: false,
          showSuccessMessage: false,
          showServiceFailedMessage: true
        })
      }
    }
    return true
  }
  /*
   * Form status: valid or invalid
   */
  getFormStatus () {
    const { formData } = this.state
    let isFormValid = true
    for (let props in formData) {
      if (
        props !== 'inquiry' &&
        props !== 'phone' /* ignore optional */ &&
        formData[props] === null
      ) {
        isFormValid = false
      }
    }
    return isFormValid
  }

  changeState (args) {
    // To do: Update the data
  }
  /*
   * Render Buttons
   */
  renderSubmitButtons () {
    const {
      props: {
        content: {
          formLabels: { buttonlabel, buttonLoadingLabel }
        }
      },
      state: { loading }
    } = this
    return (
      <div className="ButtonRow">
        <SendEmailButtonComponent
          callback={this.submitForm}
          isLoading={loading}
          defaultLabel={buttonlabel}
          loadingLabel={buttonLoadingLabel}
        />
        <button
          className="Button Button--secondary Button--secondary--action-blue"
          role="button"
          type="button"
          onClick={this.hide}
        >
          Cancel
        </button>
      </div>
    )
  }
  /*
   * Validate the form fields
   */
  validateField (fieldName, value) {
    // To do: update the newly added fields
    let emailValid = this.state.errorFields.emailaddress
    let fullnameValid = this.state.errorFields.fullname
    let fieldValues = this.state.formData
    if (!this.state.formData.inquiry) {
      fieldValues.inquiry = this.inquiryRef.current.defaultValue
    }
    let fieldErrors = this.state.errorFields
    const phoneRefNode = this.phoneRef.current
    let phoneValid = this.state.errorFields.phone

    switch (fieldName) {
    case 'fullname':
      fullnameValid = value !== '' || value.length > 0
      fieldValues.fullname = fullnameValid ? value : null
      fieldErrors.fullname = !fullnameValid
      break
    case 'phone':
      const phoneNumOnly = value.replace(/\D/g, '')
      phoneValid = phoneNumOnly.length > 0
      if (phoneValid) {
        if (phoneNumOnly.length < 10) {
          fieldErrors.phone = true
          fieldValues.phone = null
        } else if (phoneNumOnly.length === 10) {
          fieldErrors.phone = false
          phoneRefNode.value = formatPhoneNumber(phoneRefNode.value)
          fieldValues.phone = phoneRefNode.value
        } else {
          phoneRefNode.value = formatPhoneNumber(
            phoneRefNode.value
          ).substring(0, 14)
          fieldValues.phone = phoneRefNode.value
          fieldErrors.phone = false
        }
      } else {
        fieldErrors.phone = true
        fieldValues.phone = null
      }
      break
    case 'emailaddress':
      emailValid = isValidEmail(value)
      fieldValues.emailaddress = emailValid ? value : null
      fieldErrors.emailaddress = !emailValid
      fieldErrors.emailWrongFormat = false
      const formEmailAddress = this.emailaddressRef.current.value
      if (formEmailAddress && formEmailAddress.length > 0) {
        fieldErrors.emailWrongFormat = true
      }
      break
    default:
      fieldValues[fieldName] = value
      break
    }
    if (fieldName && value) {
      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
  }
  handleFieldChange (event) {
    event.preventDefault()
    const name = event.target.name
    const value = event.target.value
    this.setState(
      {
        [name]: value
      },
      () => {
        this.validateField(name, value)
      }
    )
    if (event.keyCode === 13) {
      this.validateField(name, value)
    }
  }
  /*
   * Form Submission
   */
  async submitData (event) {
    event.preventDefault()

    const {
      props: {
        callback,
        content: { formEmailInfo, formTemplate }
      },
      state: { formData }
    } = this
    const canFormBeSubmitted = this.getFormStatus()

    if (canFormBeSubmitted === true) {
      // To do: Submit the form
      // Get the data to send email
      this.setState({ loading: true })
      const items = formSelectedData(formData, formTemplate)
      if (callback && typeof callback === 'function' && items) {
        formEmailInfo.toEmailAddress = this.state.advisorEmail
        return callback(items, formEmailInfo, this.showServerResponse)
      }
      return true
    } else {
      // errorFields
      const formfullname = this.fullnameRef.current.value
      const formEmailAddress = this.emailaddressRef.current.value
      let fieldValues = this.state.formData
      let fieldErrors = this.state.errorFields
      let formPhoneNumber = this.phoneRef.current.value

      // fullname validation
      if (formfullname.length < 1) {
        fieldValues.fullname = null
        fieldErrors.fullname = true
      } else {
        fieldValues.fullname = this.state.formData.fullname
        fieldErrors.fullname = false
      }
      // Phone number validation
      if (formPhoneNumber || !formPhoneNumber.length) {
        if (
          formPhoneNumber.length < 10 ||
          (formPhoneNumber.length > 10 && formPhoneNumber.length < 14)
        ) {
          fieldValues.phone = null
          fieldErrors.phone = true
        } else if (formPhoneNumber.length === 14) {
          fieldErrors.phone = false
          fieldValues.phone = this.state.formData.phone
        } else {
          fieldErrors.phone = true
          fieldValues.phone = null
        }
      }

      // Email validation
      if (formEmailAddress || !formEmailAddress.length) {
        const cleanedText = formEmailAddress.toLowerCase()
        fieldValues.emailaddress = isValidEmail(cleanedText)
          ? formEmailAddress
          : null
        fieldErrors.emailaddress = !fieldValues.emailaddress
      }

      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
    return false
  }
  /*
   * Set the error message for email address
   */
  setEmailErrorMessage (wrongEmail, message) {
    if (wrongEmail) {
      return message.errorEmailaddressWrongFormat
    } else {
      return message.errorEmailaddress
    }
  }
  render () {
    const {
      props: {
        name,
        content: {
          formErrorMessages,
          formPleaseNote,
          serviceMessages,
          formHeading,
          formLabels
        }
      },
      state: { showServiceFailedMessage, showSuccessMessage, errorFields }
    } = this
    return (
      <div className="u-marginTop2gu">
        {showSuccessMessage &&
          serviceMessages &&
          serviceMessages.serviceSuccess && (
          <SuccessModal
            thankYou={serviceMessages.serviceSuccessThankyou}
            message={serviceMessages.serviceSuccess}
            parentForm={this}
          />
        )}
        {!showSuccessMessage && (
          <div className="u-sizeConstrained">
            <form
              name={name}
              className="contact-ira u-marginBottom5gu"
              onBlur={e => {
                this.handleFieldChange(e)
              }}
              onKeyUp={e => {
                this.handleFieldChange(e)
              }}
            >
              {formHeading &&
                formHeading.beforename &&
                this.state.advisorName && (
                <h5 className="u-marginBottom1gu">
                  {formHeading.beforename + ' ' + this.state.advisorName}
                </h5>
              )}
              {formHeading && formHeading.title && (
                <h4 className="u-marginBottom2gu">{formHeading.title}</h4>
              )}
              <div className="u-paddingBottom3gu">
                <InputField
                  name="fullname"
                  id="fullname"
                  placeholder={formLabels.fullname}
                  label=""
                  reference={this.fullnameRef}
                  error={errorFields.fullname}
                  message={formErrorMessages.errorFullname}
                  hideStyle="u-hidden"
                />
              </div>
              <div className="u-paddingBottom3gu">
                <InputField
                  name="phone"
                  id="phone"
                  placeholder={formLabels.phone}
                  label=""
                  reference={this.phoneRef}
                  hideStyle="u-hidden"
                />
              </div>
              <div className="u-paddingBottom3gu">
                <InputField
                  name="emailaddress"
                  id="email"
                  placeholder={formLabels.emailaddress}
                  label=""
                  reference={this.emailaddressRef}
                  error={errorFields.emailaddress}
                  message={this.setEmailErrorMessage(
                    errorFields.emailWrongFormat,
                    formErrorMessages
                  )}
                  hideStyle="u-hidden"
                />
              </div>
              <TextAreaField
                name="inquiry"
                id="inquiry"
                defaultValue={formLabels.inquiry}
                label=""
                reference={this.inquiryRef}
                row={3}
              />
              {showServiceFailedMessage && serviceMessages.serviceFailed && (
                <ErrorMessageComponent
                  message={serviceMessages.serviceFailed}
                />
              )}
              {this.renderSubmitButtons()}
            </form>
            {formPleaseNote && formPleaseNote.message && (
              <PleaseNoteComponent content={formPleaseNote.message} />
            )}
          </div>
        )}
      </div>
    )
  }
}

/*
 * Form default Proptypes
 */
const propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.object
}
ContactFormIRA.propTypes = propTypes
