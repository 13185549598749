import React, { Fragment } from 'react'
import StockQuote from './StockQuote.jsx'

/*
 * Market watch component
 */
export default class MarketWatch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.renderTemplate = this.renderTemplate.bind(this)
  }

  renderTemplate () {
    const {
      props: {
        content: {
          listCusipd
        }
      }
    } = this

    const cusipds = listCusipd && listCusipd.split(',')
    let symbols = []
    symbols = cusipds && cusipds.map(item => {
      const symbol = item.replace(/\s/g, '')
      symbols.unshift(symbol)
      return symbol
    })
    const uniqueSymbols = symbols.filter((value, index, self) => self.indexOf(value) === index)

    if (uniqueSymbols) {
      return (
        <StockQuote
          { ...this.props.content }
          { ...{ symbol: uniqueSymbols } }
        />
      )
    } else {
      return (
        <Fragment />
      )
    }
  }

  render () {
    return (
      <Fragment>
        { this.renderTemplate() }
      </Fragment>
    )
  }
}
