import { track } from '@cwp/utils'
import { getEnvironment } from '../environment/index'

// Enable track in lower environments
if (
  typeof track.enableOutput !== 'undefined' &&
  getEnvironment() === 'localhost'
) {
  track.enableOutput = true
}

// Add track to platform object
;(function (window) {
  window.amp = window.amp || {}
  window.amp.platform = window.amp.platform || {}
  window.amp.platform.track = track
})(window)
