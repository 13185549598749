import cookies from 'js-cookie'
import React from 'react'
import ProspectView from './views/Prospect.jsx'
import ClientView from './views/Client.jsx'
import PersonalizedView from './views/Personalized'
import { getFaceProfile } from '../../../services/advisors'
import { getStorageZip } from '../../../services/geoCoordinatesLookup'
import './Advisor.less'

const FACE_PROFILE_COOKIE = 'FaceProfileCookie'

export default class Advisor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      locPermission: true
    }

    this.updateParentState = this.updateParentState.bind(this)
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams && urlParams.get('dac') === 'no') {
      this.hideDAC = true
    }
  }

  componentDidMount () {
    this.init().catch(console.error)
  }

  async init () {
    if (await this.initClient()) return
    if (await this.initPersonalized()) return
    await this.initProspect()
  }

  updateParentState (props) {
    this.setState({ ...props })
  }

  async initProspect () {
    let zip = getStorageZip() || null
    this.setState({
      loading: false,
      zip,
      locPermission: zip || false,
      View: ProspectView
    })
  }

  async initPersonalized () {
    let success = false
    let data = {}
    try {
      const response = await fetch('/relevanceapi/visitorinfo', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => data)
      data =
        response.visitor.targetingData.advisorcollector?.extraData?.attributes
    } catch (error) {
      console.log(error)
    }

    // // Mock data for local host
    // if (window.location.hostname === 'localhost') {
    //   data = {
    //     image:
    //       'https://cdn.ameriprisecontent.com/cds/alwp/echelonwealthpartners/echelon-wealth-partners_84741_355x266.jpg',
    //     name: 'Echelon Wealth Partners',
    //     tagline:
    //       'A private wealth advisory practice of Ameriprise Financial Services, LLC',
    //     url: 'https://www.qa.ameripriseadvisors.com/team/echelon-wealth-partners',
    //     email: 'echelonwealthpartners@ampf.com',
    //     phonenumber: '123-456-7890'
    //   }
    // }

    if (data !== undefined && Object.keys(data).length > 0) {
      success = true
      this.setState({
        data: data,
        loading: false,
        View: PersonalizedView
      })
    }

    return success
  }

  async initClient () {
    const faceProfile = cookies.get(FACE_PROFILE_COOKIE)
    let success = false

    if (faceProfile) {
      const advisorRes = await getFaceProfile({ id: faceProfile })
      if (!advisorRes.errors.length) {
        success = true
        this.setState({
          data: advisorRes.data,
          loading: false,
          View: ClientView
        })
      }
    }

    return success
  }

  render () {
    if (this.hideDAC) {
      return null
    }

    let result

    if (this.state.loading) {
      result = (
        <div className="u-posRelative u-marginCenter u-padding10gu">
          <div className="Loading u-posAbsoluteCenter u-textColorBrand">
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
          </div>
        </div>
      )
    } else {
      const parentProps = {
        ...this.props,
        ...this.state,
        ...{ updateParentState: this.updateParentState }
      }
      result = <this.state.View {...parentProps} />
    }

    return <div data-analytics-container="Advisor">{result}</div>
  }
}
