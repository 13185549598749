import { startEval } from './startEval'
import {
  findSlides,
  getRes,
  disableSwipe,
  restartSlides,
  tooltipListener,
  aboutRolloverTooltip,
  clearResultsPage,
  restartResultsSlider
} from './utils.js'
import downloadResults from './downloadResults.js'
import { CardCarousel } from '../CardCarousel/index'
import './rollover.css'

/*
 * Rollover - Download method
 */
function setupDownloadLink () {
  let rolloverDownloadBts = document.querySelector(
    '.rollover-evaluator-header-results-buttons'
  )
  const summaryBtns =
    rolloverDownloadBts && rolloverDownloadBts.querySelectorAll('.Button')
  if (summaryBtns && summaryBtns.length > 0) {
    const downloadResultBtn = summaryBtns[1]
    downloadResultBtn &&
      downloadResultBtn.addEventListener('click', event => {
        event.preventDefault()
        callDownloadHtml()
      })
  }
}

function callDownloadHtml () {
  /* Read the INNERHTML from the results and pass it to the DOWNLOAD Method */
  const resultsDOM = document.querySelector(
    '#rollover-results-download-content-pdf'
  )
  if (resultsDOM) {
    try {
      /* we don't want icons in the PDF version */
      const resultsHTML =
        resultsDOM.innerHTML.replaceAll('Icon--after', '') || ''
      downloadResults(resultsHTML).catch(error => console.error(error))
    } catch (error) {
      console.error(error)
    }
  }
}

const rolloverComponent = document.querySelector('.rollover-component')
if (rolloverComponent) {
  document.rollover = {}
  directToLandingPageView()
  setupSwiper()
  aboutRolloverTooltip()
  preventTooltipLinkJumps(rolloverComponent)
  setupDownloadLink()
}
// user should be redirected to the landing page if trying to go directly to a slide
function directToLandingPageView () {
  document.rollover.state = null
  history.replaceState({ view: 'landing' }, '', window.location.pathname)
}

function setupSwiper () {
  const results = document.querySelector('.swiper-container.results')
  const evaluator = document.querySelector('.rollover-evaluator')
  const resultsView = document.querySelector('.rollover-results-view')
  const resultsSummary = document.querySelector('.rollover-results-summary')
  const start = evaluator.querySelector('.start-eval')
  const container = evaluator.querySelector('.swiper-container')
  const overview = resultsView.querySelector('.pros-overview')
  const resultsHeaderView = document.querySelector(
    '.rollover-evaluator-header-results'
  )
  document.rollover.count =
    container.querySelector('.swiper-wrapper').children.length

  let resultsSwiper
  if (results) {
    resultsSwiper = new CardCarousel(results)
  }

  if (container) {
    const userEntrySwiper = new CardCarousel(container, { tabbing: true })
    const nextButton = container.querySelector("[data-action='slideRight']")
    userEntrySwiper.init()
    startEval(direction => {
      findSlides()
      updateURLwithQueryQuestions(userEntrySwiper.currentCardIndex(), direction)
    })

    window.addEventListener('popstate', function (evt) {
      const { state } = evt
      if (!state) return
      if (state.view === 'landing') {
        start.style.display = 'block'
        container.style.display = 'none'
      } else if (state.view === 'results') {
        if (overview.textContent) {
          const activeSlide = evaluator.querySelector('.swiper-slide.active')
          clearResultsPage()
          getRes(activeSlide)
          evaluator.style.display = 'none'
          resultsView.style.display = 'block'
          resultsHeaderView.style.display = 'block'
          resultsSummary.style.display = 'block'
        } else {
          history.forward()
        }
      } else if (document.rollover.state === 'landing') {
        start.style.display = 'none'
        container.style.display = 'block'
        resultsHeaderView.style.display = 'none'
      } else if (document.rollover.state === 'results') {
        evaluator.style.display = 'block'
        resultsView.style.display = 'none'
        resultsHeaderView.style.display = 'none'
        resultsSummary.style.display = 'none'
      } else {
        const direction =
          document.rollover.state > state.view
            ? 'leftArrowEvent'
            : 'rightArrowEvent'
        if (direction === 'rightArrowEvent') {
          getRes(evaluator.querySelector('.swiper-slide.active'))
        }
        userEntrySwiper[direction]()
      }

      document.rollover.state = state.view
    })

    userEntrySwiper.on('slideNextTransitionStart', function () {
      const activeSlide = evaluator.querySelector('.swiper-slide.active')
      const isLastSlide = activeSlide.classList.contains('is-last')
      const isRightClick = this.dataset && this.dataset.action === 'slideRight'
      const isInitState = overview.textContent === ''

      if (isRightClick && isLastSlide && isInitState) {
        getRes(activeSlide)
        resultsSwiper = new CardCarousel(results, { defaultNav: true })
        resultsSwiper.init()
      } else if (isRightClick && isLastSlide) {
        clearResultsPage()
        getRes(activeSlide)
      } else if (isRightClick) {
        getRes(activeSlide)
      }
    })

    nextButton.addEventListener('click', () => {
      updateURLwithQueryQuestions(userEntrySwiper.currentCardIndex(), 'next')
    })
    userEntrySwiper.on('transitionEnd', () => {
      disableSwipe()
      findSlides()
      tooltipListener()
    })

    setupShare()
    setupAdvisorName()
    setupRestart(userEntrySwiper, resultsSwiper)
  }
}

function setupRestart (userEntrySwiper, resultsSwiper) {
  // restart
  const restartButton = document.querySelector('.restartLink')
  if (restartButton) {
    restartButton.addEventListener('click', () => {
      clearResultsPage()
      userEntrySwiper.slideTo(0)
      updateURLwithQueryQuestions()
      restartSlides()
      restartResultsSlider(resultsSwiper)
    })
  }
}

function setupShare () {
  const shareAdvisorButton = document.querySelector('.shareAdvisor')
  const shareModalForm = document.querySelector('.Modal.rollover-share')
  const shareClose =
    shareModalForm &&
    shareModalForm.querySelector('Button--secondary--action-blue')

  if (shareAdvisorButton) {
    shareAdvisorButton.addEventListener('click', () => {
      shareModalForm.style.display = 'block'
      const shareForm = document.querySelector('.contact-ira')
      const resultsDOM = document.querySelector(
        '#rollover-results-download-content-pdf'
      )
      if (resultsDOM) {
        const resultsHTML = resultsDOM.innerHTML || ''
        if (shareForm) shareForm.answers = resultsHTML
      }
    })
  }
  if (shareClose) {
    shareClose.addEventListener('click', () => {
      alert('hiding')
      shareModalForm.style.display = 'none'
      alert('hidden')
    })
  }
}

function setupAdvisorName () {
  const component = document.querySelector('advisor-profile')
  component &&
    component.addEventListener('profile-loaded', event => {
      let advisorElt = document.querySelector('#advisorText')
      advisorElt.innerHTML = advisorElt.innerHTML.replace(
        '%advisor%',
        event.profile.name
      )
      let resultsElt = document.querySelector(
        '#rollover-results-download-content-pdf'
      )
      resultsElt.innerHTML = resultsElt.innerHTML.replace(
        '%advisor%',
        event.profile.name
      )
      if (!event.profile.email) {
        const emailBtn = document.querySelector(
          '.rollover-results-summary .Button--primary'
        )
        emailBtn.classList.add('u-hidden')
      }
    })
}

function updateURLwithQueryQuestions (currentSlide = 0, direction = '') {
  const view = currentSlide + 1
  const { rollover } = document

  if (rollover.state === rollover.count && direction === 'next') {
    rollover.state = 'results'
    history.pushState(
      { view: 'results' },
      '',
      window.location.pathname + '?results'
    )
  } else if (rollover.state === 1 && direction === 'prev') {
    rollover.state = 'landing'
    history.pushState({ view: 'landing' }, '', window.location.pathname)
  } else {
    rollover.state = view
    history.pushState(
      { view },
      '',
      window.location.pathname + '?question=' + view
    )
  }
}

function preventTooltipLinkJumps (component) {
  component.querySelectorAll('.rollover-question a').forEach(element => {
    element.addEventListener('click', e => e.preventDefault())
  })
}

export { setupSwiper, updateURLwithQueryQuestions }
