import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const DropdownMenu = props => {
  const {
    onChangeHandler,
    items,
    currentItem,
    summaryBeforeText,
    summaryAfterText,
    testId = false
  } = props

  const [open, setOpen] = useState(false)
  const [focusIndex, setFocusIndex] = useState(undefined)
  const menuRef = useRef()
  const itemsRef = useRef([])

  const handleArrowUp = useCallback(() => {
    if (focusIndex === 0) {
      return
    }
    if (focusIndex === undefined) {
      itemsRef.current[0].focus()
      setFocusIndex(0)
      return
    }
    itemsRef.current[focusIndex].focus()
    itemsRef.current[focusIndex - 1].focus()
    setFocusIndex(focusIndex - 1)
  }, [focusIndex])
  const handleArrowDown = useCallback(() => {
    if (focusIndex === items.length - 1) {
      return
    }
    if (focusIndex === undefined) {
      itemsRef.current[0].focus()
      setFocusIndex(0)
      return
    }
    itemsRef.current[focusIndex].focus()
    itemsRef.current[focusIndex + 1].focus()
    setFocusIndex(focusIndex + 1)
  }, [focusIndex, items.length])
  const handleKeyDown = useCallback(
    e => {
      const { code } = e
      if (!open || (code !== 'ArrowUp' && code !== 'ArrowDown')) {
        return
      }
      e.preventDefault()
      if (code === 'ArrowUp') {
        handleArrowUp()
        return
      }
      handleArrowDown()
    },
    [open, handleArrowUp, handleArrowDown]
  )

  const handleWindowClick = useCallback(
    e => {
      if (!menuRef.current || menuRef.current.contains(e.target) || !open) {
        return
      }
      setOpen(false)
      if (focusIndex !== undefined) {
        itemsRef.current[focusIndex].focus()
      }
      setFocusIndex(undefined)
    },
    [open, focusIndex]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('click', handleWindowClick)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.addEventListener('click', handleWindowClick)
    }
  }, [handleKeyDown, handleWindowClick])

  const onChangeCallback = item => {
    setOpen(false)
    setFocusIndex(undefined)
    if (focusIndex !== undefined) {
      itemsRef.current[focusIndex].focus()
    }
    onChangeHandler(item)
  }

  return (
    <div
      className="DropdownMenu Accordion u-flex u-flexJustifyCenter u-posRelative"
      data-testid={testId || null}
    >
      <div className="Content DropdownMenu-content">
        <div role="tab" className="accordion-target card-summary">
          <h2>
            {summaryBeforeText}{' '}
            <button
              className="Dropdown-toggle Link Link--Trigger u-padding0 u-inlineBlock"
              onClick={e => {
                e.stopPropagation()
                setOpen(!open)
                setFocusIndex(undefined)
              }}
              onMouseDown={e => {
                e.preventDefault()
              }}
              aria-haspopup={true}
            >
              {`${currentItem} `}
            </button>{' '}
            {summaryAfterText}
          </h2>
        </div>
        {open && (
          <ul
            className="Tooltip u-padding0 u-posAbsolute u-margin0"
            aria-expanded={open}
            ref={menuRef}
          >
            {items.map((item, index) => {
              return (
                <li
                  className="Content accordion-item u-paddingHoriz2gu u-posRelative u-marginBottom0"
                  key={`${item}${index}`}
                  onClick={() => {
                    onChangeCallback(item)
                  }}
                >
                  <button
                    className="Link Link--inlineBlock u-textColorActionBlue u-paddingHoriz2gu u-sizeFull"
                    onMouseDown={e => {
                      e.preventDefault()
                    }}
                    ref={el => (itemsRef.current[index] = el)}
                    aria-selected={item === currentItem}
                  >
                    {item === currentItem ? (
                      <strong>{item}</strong>
                    ) : (
                      <>{item}</>
                    )}
                  </button>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}
DropdownMenu.defaultProps = {
  items: []
}
DropdownMenu.propTypes = {
  onChangeHandler: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.string),
  currentItem: PropTypes.string,
  summaryBeforeText: PropTypes.string,
  summaryAfterText: PropTypes.string
}
export default DropdownMenu
