import React, { useState, useRef } from 'react'
import './AnchorLinks.less'
import xss from 'xss'

const AnchorLinks = ({ content, testId }) => {
  const {
    columns,
    backgroundColor,
    backgroundContrast,
    title,
    templateContent,
    imageSrcPath
  } = content

  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false)
  const containerRef = useRef(null)
  const toggleVisibility = () => setIsSidePanelVisible(prevState => !prevState)
  const handleAnchorClick = (event, toggleMobileSidePanel = false) => {
    if (event.target.tagName === 'A') {
      addSmoothScroll(event)
      toggleMobileSidePanel && toggleVisibility()
    }
  }

  const addSmoothScroll = e => {
    const href = e.target.getAttribute('href')
    const targetId = href && href.replace(/^#/, '')
    const targetElement = targetId && document.getElementById(targetId)
    if (targetElement) {
      e.preventDefault()
      targetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <div className={`AnchorLinks ${backgroundColor}`}>
        <div
          className={`u-sm-hidden ${backgroundContrast} ${
            columns === 'Two Columns' && 'AnchorLinks-two-columns'
          }`}
          data-testid={testId ? `${testId}-desktop-section` : null}
        >
          {<Heading title={title} icon isMobile={false} testId={testId} />}
          <div
            onClick={handleAnchorClick}
            className="u-paddingHoriz4gu u-paddingBottom4gu u-paddingTop2gu"
            dangerouslySetInnerHTML={{ __html: xss(templateContent) }}
          />
        </div>

        <AnchorMobileMenu
          title={title}
          toggleVisibility={toggleVisibility}
          imageSrcPath={imageSrcPath}
          testId={testId}
        />

        <SidePanel
          title={title}
          isSidePanelVisible={isSidePanelVisible}
          toggleVisibility={toggleVisibility}
          templateContent={templateContent}
          containerRef={containerRef}
          handleAnchorClick={handleAnchorClick}
          testId={testId}
        />
      </div>
    </>
  )
}

const Heading = ({ title, icon, isMobile, isSidePanelVisible, testId }) => (
  <div
    className={`${
      !isMobile &&
      'u-paddingBottom4gu u-paddingTop6gu u-paddingHoriz6gu u-marginBottom0 u-borderBottom u-borderColorNeutral'
    }`}
  >
    <h4
      className={`Icon--after u-textPrimary u-textNormal ${
        isSidePanelVisible
          ? 'u-textSizeMsrp4 u-textColorNeutralGrey'
          : 'u-textSizeMsrp2'
      }`}
      data-icon={icon ? '\ue157' : undefined}
      data-testid={testId ? `${testId}-heading` : null}
    >
      {title || 'In this article'}
    </h4>
  </div>
)

const AnchorMobileMenu = ({
  title,
  toggleVisibility,
  imageSrcPath,
  testId
}) => (
  <div
    className={`AnchorLinks-mobile-container u-bgColorStone u-flex u-flexAlignItemsCenter u-paddingHoriz4gu u-md-hidden u-lg-hidden u-flexJustifyBetween`}
  >
    {<Heading title={title} testId={testId} isMobile />}
    <button
      className="SidePanel--trigger u-flex u-flexAlignItemsCenter"
      onClick={toggleVisibility}
    >
      <img
        src={`${imageSrcPath}/menu-open-icon.svg`}
        alt="Open Menu"
        aria-hidden="true"
        height="16"
        width="30"
      />
      <span className="u-hiddenVisually">Open Menu</span>
    </button>
  </div>
)

const SidePanel = ({
  title,
  isSidePanelVisible,
  toggleVisibility,
  templateContent,
  containerRef,
  handleAnchorClick,
  testId
}) => (
  <aside
    className={`SidePanel ${
      isSidePanelVisible && 'is-visible'
    } u-md-hidden u-lg-hidden`}
  >
    <section
      className={`SidePanel-main ${!isSidePanelVisible && 'u-hidden'}`}
      data-testid={testId ? `${testId}-side-panel` : null}
    >
      <div className="SidePanel-topWrapper">
        <header className="SidePanel-headerWrapper u-borderBottom u-borderColorNeutral">
          <div className="SidePanel-header">
            <div className="SidePanel-heading">
              {
                <Heading
                  title={title}
                  isMobile
                  isSidePanelVisible={isSidePanelVisible}
                  testId={testId}
                />
              }
            </div>
            <button
              className={`Button Button--icon SidePanel-Button ${
                !isSidePanelVisible && 'u-hidden'
              }`}
              onClick={toggleVisibility}
              title="Close panel"
            >
              <span className="u-hiddenVisually">Close panel</span>
              <span
                aria-hidden="true"
                dangerouslySetInnerHTML={{ __html: xss('&#x2297;') }}
              />
            </button>
          </div>
        </header>
        <div className="SidePanel-sectionsWrapper">
          <div
            ref={containerRef}
            onClick={e => handleAnchorClick(e, true)}
            className={`u-paddingHoriz2gu u-paddingVert2gu ${
              !isSidePanelVisible && 'u-hidden'
            }`}
            dangerouslySetInnerHTML={{ __html: xss(templateContent) }}
          />
        </div>
      </div>
    </section>
  </aside>
)

export default AnchorLinks
