import React from 'react'

const LoadMoreButton = props => {
  return (
    <div className="u-flex u-flexJustifyCenter">
      <button
        className="Button Button--secondary Button--secondary--action-blue"
        onClick={() => props.handleLoadMoreClick()}
      >
        Load More
      </button>
    </div>
  )
}

export default LoadMoreButton
