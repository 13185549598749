import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CardContent from './CardContent'

/**
 *
 * @param {*} props
 * @returns
 */
const Card = props => {
  const { card, onFocus } = props
  const { cardCategory, cardArticle, featureFlag, cardContent } = card

  const [cardValues, setCardValues] = useState({})

  useEffect(() => {
    if (cardArticle) {
      setCardValues(cardArticle)
      return
    }
    setCardValues(cardContent)
  }, [cardArticle, cardContent])

  const getCardContent = () => {
    const {
      cardDescription,
      heading,
      superHeadline,
      author,
      readTime,
      date,
      cardImage
    } = cardValues
    return (
      <div
        className={`Card Card--withHeader u-flex u-flexCol u-paddingBottom4gu u-posRelative u-bgColorWhite u-sizeFull`}
        card-category={cardCategory}
      >
        <CardContent
          featureFlag={featureFlag}
          cardDescription={cardDescription}
          heading={heading}
          superHeadline={superHeadline}
          author={author}
          readTime={readTime}
          date={date}
          cardImage={cardImage}
        />
      </div>
    )
  }

  if (cardValues.url) {
    return (
      <a
        href={cardValues.url}
        className="u-linkClean Card-Border"
        data-analytics={cardValues.dataAnalytics}
        onFocus={onFocus}
        onMouseDown={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {getCardContent()}
      </a>
    )
  }

  return getCardContent()
}
Card.propTypes = {
  card: PropTypes.object,
  collapse: PropTypes.bool,
  featureFlag: PropTypes.object,
  onFocus: PropTypes.func
}
export default Card
