import React, { Fragment } from 'react'

/*
 * Helper methods
 */
import { setInnerHtml } from './utils'

/*
 *  Error message
 */
export const ErrorMessageComponent = ({ message, dataAnalytics }) => {
  const dataQaError = 'Message--error'
  const dataAnalyticsMsg = dataAnalytics || dataQaError
  return (
    <div
      className="Notification Notification--withIcon Notification--alert u-marginBottom5gu"
      role="alert"
    >
      <div
        className="Notification-content js-formNotification"
        data-qa={dataQaError}
        data-analytics={dataAnalyticsMsg}
        data-icon={'\ue002'}
      >
        <p dangerouslySetInnerHTML={setInnerHtml(message)} />
      </div>
    </div>
  )
}

/*
 *  Please note content
 */
export const PleaseNoteComponent = ({ content }) => {
  return (
    <div
      className="Content u-marginBottom5gu"
      dangerouslySetInnerHTML={setInnerHtml(content)}
    />
  )
}

/*
 *  Send email button
 */
export const SendEmailButtonComponent = ({
  callback,
  isLoading,
  defaultLabel,
  loadingLabel,
  dataAnalytics
}) => {
  const dataQa = 'Button--send'
  const dataTracking = 'Button'
  const classList = 'Button Button--primary'
  const showButtonLabels = () => {
    if (isLoading) {
      return (
        <Fragment>
          {loadingLabel}
          <div className="Loading u-marginLeft2gu u-textColorWhite">
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
          </div>
        </Fragment>
      )
    } else {
      return <Fragment>{defaultLabel}</Fragment>
    }
  }
  return (
    <button
      className={classList}
      data-tracking={dataTracking}
      data-qa={dataQa}
      onClick={callback}
      data-analytics={dataAnalytics || dataTracking}
    >
      {showButtonLabels()}
    </button>
  )
}
