/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { string, node } from 'prop-types';

/*
 * <MicrositeFooter />
 * Renders footer.
 */

const MicrositeFooter = props => {
  const [year, setYear] = useState(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear);
  }, []);

  return (
    <footer className="footer u-decoBorderLight">
      <div className="ms-footerPrint u-bgColorLight u-paddingBottom10gu">
        <div className="ms-printLeftContainer u-sizeConstrained u-flex">
          <div className="u-sizeFill u-flex u-sm-flexCol u-md-flexCol u-flexAlignItemsCenter u-sm-flexAlignItemsStart u-md-flexAlignItemsStart u-marginLeftM1gu u-marginBottom2gu">
            <div className="ms-listItem u-marginVert3gu">
              <p className="u-padding1gu">
                <a
                  className="Link Link--currentColor"
                  href="/legal/disclosures"
                >
                  Corporate entities and important disclosures
                </a>
              </p>
            </div>
            <div className="u-sm-hidden u-md-hidden u-decoBorderLight u-decoBorderResetTBL footer-border u-marginRight2gu"></div>
            <div className="ms-listItem u-marginVert3gu">
              <p className="u-padding1gu">
                <a
                  className="Link Link--currentColor"
                  href="/legal/website-rules"
                >
                  Website rules, regulations and disclaimers
                </a>
              </p>
            </div>
            <div className="u-sm-hidden u-md-hidden u-decoBorderLight u-decoBorderResetTBL footer-border u-marginRight2gu"></div>
            <div className="ms-listItem u-marginVert3gu">
              <p className="u-padding1gu">
                <a
                  className="Link Link--currentColor"
                  href="/privacy-security-fraud/online-security-guarantee"
                >
                  Online security guarantee
                </a>
              </p>
            </div>
            <div className="u-sm-hidden u-md-hidden u-decoBorderLight u-decoBorderResetTBL footer-border u-marginRight2gu"></div>
            <div className="ms-listItem u-marginVert3gu">
              <p className="u-padding1gu">
                <a
                  className="Link Link--currentColor"
                  href="/privacy-security-fraud/fraud-reporting/email-fraud"
                >
                  About email fraud
                </a>
              </p>
            </div>
            <div className="u-sm-hidden u-md-hidden u-decoBorderLight u-decoBorderResetTBL footer-border u-marginRight2gu"></div>
            <div className="ms-listItem u-marginVert3gu">
              <p className="u-padding1gu">
                <a
                  className="Link Link--currentColor"
                  href="/legal/client-relationship-summary"
                >
                  Client Relationship Summary
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="u-sizeConstrained">
          <p className="ms-userAgreeTerms u-textColorLight u-marginBottom6gu">
            Users of this site agree to be bound by the terms of the Ameriprise
            Financial Website Rules and Regulations.
          </p>
          <p className="u-textColorLight">
            © <span className="msCurrentYear">{year}</span> Ameriprise
            Financial, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

MicrositeFooter.propTypes = {
  className: string,
  children: node,
  nortonLogo: string
};

MicrositeFooter.defaultProps = {
  children: null,
  className: '',
  nortonLogo: ''
};

export default MicrositeFooter;
