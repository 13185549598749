import React, { Fragment } from 'react'
import getStockQuotes from '../../../services/getStockQuote'
import { StockQuoteData } from './StockQuoteData.jsx'
import { CONSTs, setTimeStamp, SERVICEAPI } from '../views/utils'

const issuerMap = {
  'DOW JONES INDUSTRIAL AVERAGE': 'DJIA',
  'NASDAQ COMPOSITE': 'NASDAQ',
  'S&P 500 INDEX': 'S&P 500',
  'AMERIPRISE FINANCIAL, INC': 'AMP'
}
/*
 * Stock Quote component
 */
export default class StockQuote extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      marketData: [],
      lastUpdated: null,
      errors: null
    }

    this.renderStocks = this.renderStocks.bind(this)
    this.renderQuotes = this.renderQuotes.bind(this)
    this.renderContent = this.renderContent.bind(this)
    this.disclaimerMessages = this.disclaimerMessages.bind(this)
  }

  componentDidMount () {
    this.initData()
  }

  initData () {
    const {
      props: { symbol }
    } = this

    if (symbol) {
      const params = {
        url: SERVICEAPI.URL,
        length: symbol.length,
        data: {
          cusip: [],
          symbol: symbol
        },
        headers: {
          consumer: SERVICEAPI.CONSUMERID
        },
        callback: (data, message) => {
          this.renderStocks(data, message)
        }
      }
      return getStockQuotes(params)
    }
  }

  renderStocks (item, message) {
    if (message === CONSTs.SUCCESS) {
      if (item && item.length === this.props.symbol.length) {
        this.setState({
          marketData: item,
          loading: false,
          lastUpdated: setTimeStamp(item)
        })
      }
    } else {
      this.setState({
        marketData: [],
        loading: false,
        lastUpdated: null,
        errors: message
      })
    }
  }

  disclaimerMessages (type) {
    let message = ''
    const {
      props: {
        previousPriceDisclaimer,
        dividend: {
          exDividendDisclaimer,
          exDividendSymbol,
          preDividendDisclaimer,
          preDividendSymbol
        }
      }
    } = this
    switch (type) {
    case CONSTs.DISCLAIMERS.prevClose:
      message = previousPriceDisclaimer
      break
    case CONSTs.DISCLAIMERS.preDividend:
      message = `<p><span class="Icon--before" data-icon=${preDividendSymbol}></span>${preDividendDisclaimer}</p>`
      break
    case CONSTs.DISCLAIMERS.exDividend:
      message = `<p><span class="Icon--before" data-icon=${exDividendSymbol}></span>${exDividendDisclaimer}</p>`
      break
    default:
      message = ''
      break
    }
    return message
  }

  displayDisclaimer (data) {
    let message = ''
    let prevCloseMessage = ''
    data.map(item => {
      if (parseFloat(item.quoteInfo.prcChg) === 0) {
        prevCloseMessage = this.disclaimerMessages(
          CONSTs.DISCLAIMERS.prevClose
        )
      }
      if (item.dividend !== null) {
        // Check when the stock is trading pre ex-dividend
        if (item.dividend.pendExDivdInd === 'PendingExDividend') {
          message = this.disclaimerMessages(CONSTs.DISCLAIMERS.preDividend)
        }
        // Check when the stock is trading ex-dividend
        if (item.dividend.exDivdInd === 'TradingExDividend') {
          message = this.disclaimerMessages(CONSTs.DISCLAIMERS.exDividend)
        }
      }
    })
    return prevCloseMessage + message
  }

  renderQuotes (items, lastUpdated) {
    if (items && items.length === this.props.symbol.length) {
      const {
        props: {
          previousCloseLabel,
          unChangeLabel,
          dividend: { exDividendSymbol, preDividendSymbol }
        }
      } = this
      let qualityOfService
      let divSymbol = ''
      const refinitiveDisclaimer = `<div id=Disclaimer-Text>${
        this.props.disclaimer + this.displayDisclaimer(items)
      }</div>`
      items.map(item => {
        qualityOfService = item.quoteInfo.qltyOfServ
      })
      return (
        <table className="Table" id="stockQuote">
          <colgroup>
            <col className="u-size2of12" />
            <col className="u-size4of12" />
            <col className="u-size2of12" />
          </colgroup>
          <tbody className="Table-body">
            <tr className="Table-row">
              <td
                className="Table-cell u-textColorLight u-paddingBottom2gu u-textSizeMsrm1"
                colSpan="3"
                id="qualityOfService"
              >
                As of {lastUpdated} ({qualityOfService})
              </td>
            </tr>
            {items.map(item => {
              const { prcChg, lstTrdPrc, open } = item.quoteInfo
              const getStockQuote = StockQuoteData(
                prcChg,
                lstTrdPrc,
                previousCloseLabel,
                unChangeLabel,
                open
              )
              const previousPriceLabel = getStockQuote.dCode
              const price = getStockQuote.price
              const classes = getStockQuote.classes
              const arrow = getStockQuote.arrow
              const priceChange = getStockQuote.prcChg

              if (item.dividend !== null) {
                if (item.dividend.pendExDivdInd === 'PendingExDividend') {
                  divSymbol = (
                    <span
                      className="Icon--before js-preExDivididend-icon"
                      data-icon={preDividendSymbol}
                    ></span>
                  )
                }
                if (item.dividend.exDivdInd === 'TradingExDividend') {
                  divSymbol = (
                    <span
                      className="Icon--before js-exDividend-icon"
                      data-icon={exDividendSymbol}
                    ></span>
                  )
                }
              }
              const cleanSymbol = item.symbol
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()

              return (
                <tr
                  className={`Table-row row-${cleanSymbol}`}
                  key={cleanSymbol}
                >
                  <td
                    className={`Table-cell cell-${cleanSymbol} u-textColorDefault u-textNoWrap u-textSizeMsrm1`}
                  >
                    {issuerMap[item.description.issuerNm]}
                  </td>
                  <td className="Table-cell u-textSizeMsrm1 u-textRight u-textColorDefault u-textNoWrap u-paddingRight1gu">
                    {divSymbol}
                    {previousPriceLabel}
                    {price} {arrow}
                  </td>
                  <td className={`${classes} u-textRight`}>{priceChange}</td>
                </tr>
              )
            })}
            <tr className="Table-row js-row-disclaimer">
              <td
                className="Table-cell"
                colSpan="3"
                dangerouslySetInnerHTML={{ __html: refinitiveDisclaimer }}
              ></td>
            </tr>
          </tbody>
        </table>
      )
    }
  }

  errorCode (errors) {
    let errorCodes = ''
    if (errors !== null) {
      if (Array.isArray(errors)) {
        errors.map(error => {
          errorCodes += error.explCd
        })
      } else {
        errorCodes = errors
      }
      return (
        <p className="u-textColorDefault u-floatLeft">
          &#40;Error Code {errorCodes}&#41;
        </p>
      )
    }
  }

  renderContent () {
    const {
      state: { loading, marketData, lastUpdated, errors }
    } = this

    if (loading && !marketData.length) {
      return (
        <div className="u-posRelative u-marginCenter u-padding10gu">
          <div className="Loading u-posAbsoluteCenter u-textColorBrand">
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
          </div>
        </div>
      )
    } else if (!loading && !marketData.length) {
      return (
        <div className="u-posRelative u-marginCenter u-padding5gu js-errorMessage">
          <h3>{this.props.errorHeading}</h3>
          <div
            className="u-paddingTop2gu u-floatLeft"
            dangerouslySetInnerHTML={{ __html: this.props.errorMessage }}
          />{' '}
          {this.errorCode(errors)}
        </div>
      )
    } else {
      return <Fragment>{this.renderQuotes(marketData, lastUpdated)}</Fragment>
    }
  }

  render () {
    return <Fragment>{this.renderContent()}</Fragment>
  }
}
