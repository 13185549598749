import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './skip-links.less'
import SkipLink from './SkipLink'

const SkipLinkContainer = props => {
  const { testId, content } = props
  const { showContactUs } = content
  const [skipLinks, setSkipLinks] = useState([])
  const [isTabbing, setIsTabbing] = useState(false)

  const possibleSkipLinks = [
    {
      id: 'skipToMainContent',
      href: '#main',
      label: 'Skip to main content',
      targetSelector: 'main',
      focusSelector: 'main a'
    },
    {
      id: 'skipToLogin',
      href: '#login',
      label: 'Skip to login',
      targetSelector: '.LoginClient',
      focusSelector: '.LoginClient input'
    },
    {
      id: 'skipToAdvisor',
      href: '#advisor',
      label: 'Skip to Find An Advisor',
      targetSelector: '[data-is="Advisor"]',
      focusSelector: '[data-is="Advisor"] input'
    },
    {
      id: 'skipToResults',
      href: '#results',
      label: 'Skip to Results',
      targetSelector: '#ResourceCenterResults',
      focusSelector: '#ResourceCenterResults .results .Card'
    },
    {
      id: 'skipToContactUs',
      href: '/customer-service/contact',
      label: 'Navigate to Contact Us'
    },
    {
      id: 'skipToFooter',
      href: '#footer',
      label: 'Skip to footer',
      targetSelector: 'footer.footer',
      focusSelector: () => {
        window.dispatchEvent(new CustomEvent('skipfocus', { detail: 'a' }))
      }
    }
  ]

  const generateSkipLinks = (show, e) => {
    const presentSkipLinks = possibleSkipLinks.filter(currItem => {
      const { targetSelector } = currItem
      if (targetSelector) {
        if (document.querySelector(targetSelector)) {
          return true
        }
        return false
      }
      if (!showContactUs) {
        return false
      }
      return true
    })

    // if null that means going forward and should focus on first skip link
    // if not null then going backwards. from site to last skip link
    let focusIndex = 0
    if (e && e.relatedTarget) {
      focusIndex = presentSkipLinks.length - 1
    }

    const newSkipLinks = presentSkipLinks.map((currItem, index) => {
      return {
        id: currItem.id,
        href: currItem.href,
        index: index + 1,
        targetSelector: currItem.targetSelector,
        focusSelector: currItem.focusSelector,
        label: currItem.label,
        shouldFocusOnMount: show && focusIndex === index,
        key: currItem.label
      }
    })
    setSkipLinks(newSkipLinks)
  }

  // update skip links on every time tab is pressed
  useEffect(() => {
    const handleTab = event => {
      if (event.key === 'Tab') {
        generateSkipLinks(true)
        setIsTabbing(true)
      }
    }

    window.addEventListener('keydown', handleTab)

    return () => {
      window.removeEventListener('keydown', handleTab)
    }
  }, [isTabbing, possibleSkipLinks])

  // create skip link to main content for accessibility checker
  useEffect(() => {
    generateSkipLinks(false)
  }, [])

  return (
    <div className="u-posAbsolute u-sizeFull" data-testid={testId || null}>
      {skipLinks.map(currItem => {
        return (
          <SkipLink
            id={currItem.id}
            href={currItem.href}
            index={currItem.index}
            targetSelector={currItem.targetSelector}
            focusSelector={currItem.focusSelector}
            label={currItem.label}
            shouldFocusOnMount={currItem.shouldFocusOnMount}
            key={currItem.label}
          />
        )
      })}
    </div>
  )
}
SkipLinkContainer.propTypes = {
  content: PropTypes.shape({
    showContactUs: PropTypes.oneOf(['true', ''])
  }),
  testId: PropTypes.string
}
export default SkipLinkContainer
