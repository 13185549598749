import React from 'react'
import './Spotlight.less'
import BrandIcon from '../BrandIcon'

const Spotlight = props => {
  const { content, testId } = props

  return (
    <div className="Spotlight u-sizeConstrained u-sm-paddingVert4gu u-md-paddingVert4gu u-paddingVert6gu">
      <div className="Grid">
        {content.headingLeft && (
          <div className="u-md-size1of2 u-lg-size1of2 u-sm-paddingRight0 u-md-paddingRight10gu u-paddingRight10gu u-sm-paddingBottom6gu u-md-paddingBottom6gu u-md-paddingTop6gu Spotlight-border">
            <BrandIcon
              icon={content.iconLeft}
              className={content.iconColorLeft}
              testId={testId ? `${testId}-icon-left` : null}
            />
            <div
              className="u-paddingTop4gu u-paddingBottom2gu"
              dangerouslySetInnerHTML={{ __html: content.headingLeft }}
              data-testid={testId ? `${testId}-heading-left` : null}
            />
            <div
              className="u-paddingBottom6gu"
              dangerouslySetInnerHTML={{ __html: content.contentLeft }}
              data-testid={testId ? `${testId}-content-left` : null}
            />
            <a
              className="Button Button--secondary Button--secondary--action-blue"
              data-analytics={content.linkAnalyticsLeft}
              href={`${content.linkUrlLeft}`}
              target={content.linkTargetLeft}
            >
              {content.linkTextLeft}
            </a>
          </div>
        )}
        {content.headingRight && (
          <div className="u-md-size1of2 u-lg-size1of2 u-sm-paddingLeft0 u-md-paddingLeft10gu u-paddingLeft10gu u-sm-paddingTop6gu u-md-paddingTop6gu">
            <BrandIcon
              icon={content.iconRight}
              className={content.iconColorRight}
              testId={testId ? `${testId}-icon-right` : null}
            />
            <div
              className="u-paddingTop4gu u-paddingBottom2gu"
              dangerouslySetInnerHTML={{ __html: content.headingRight }}
              data-testid={testId ? `${testId}-heading-right` : null}
            />
            <div
              className="u-paddingBottom6gu"
              dangerouslySetInnerHTML={{ __html: content.contentRight }}
              data-testid={testId ? `${testId}-content-right` : null}
            />
            <a
              className="Button Button--secondary Button--secondary--action-blue"
              data-analytics={content.linkAnalyticsRight}
              href={`${content.linkUrlRight}`}
              target={content.linkTargetRight}
            >
              {content.linkTextRight}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default Spotlight
