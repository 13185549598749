import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './ios-banner.css'

const IosBanner = props => {
  const { show, testId } = props

  const localStorageTimeStamp = Number(
    localStorage.getItem('iosBannerDelayTimeStamp')
  )

  const ua = window.navigator.userAgent
  const isIOS = /iPad|iPhone|iPod/i.test(ua) && !window.MSStream
  const isSafari = ua.includes('Safari') && !ua.includes('CriOS')
  const iosLogo = `${window.AMPCOMPATH}images/amp-ios-icon.png`
  const closeButton = `${window.AMPCOMPATH}images/PNG-close-icon.png`
  const mobileUrl =
    'https://apps.apple.com/app/apple-store/id437992173?pt=458199%26ct=smart-banner-ns%26mt=8'

  const isEnabled = (isIOS && !isSafari) || show === 'always'

  const [withinTimeFrame, setWithinTimeFrame] = useState(true)
  const [open, setOpen] = useState(true)

  const closeBanner = () => {
    if (open) {
      localStorage.setItem('iosBannerDelayTimeStamp', Date.now())
    }
    setOpen(false)
  }

  useEffect(() => {
    if (show === 'always' || !isIOS || isSafari) {
      return
    }

    // TODO why was this value chosen?
    const delayInMilliseconds = 3 * 28 * 24 * 60 * 60 // 2.016 hours ago
    const now = new Date()
    const dateDelay = new Date(now).setTime(now.getTime() - delayInMilliseconds)
    if (localStorageTimeStamp < dateDelay) {
      setWithinTimeFrame(true)
    }
  }, [show, isIOS, isSafari, localStorageTimeStamp])

  if (!isEnabled || !open || !withinTimeFrame) {
    return null
  }

  return (
    <div id="ios-mobile-banner" className="ios-banner-container">
      <div className="ios-banner-grid">
        <div className="ios-banner-grid-col x">
          <button
            data-testid={testId ? `${testId}-close-btn` : null}
            className="ios-banner-close"
            data-analytics="mobile-app-banner-dismiss-button"
            onClick={() => closeBanner()}
          >
            <img
              src={closeButton}
              alt="close banner button"
              width="10px"
              height="10px"
            />
          </button>
        </div>
        <div className="ios-banner-grid-col image">
          <img
            src={iosLogo}
            alt="Ameriprise mobile app link"
            width="64px"
            height="64px"
          />
        </div>
        <div
          className="ios-banner-grid-col text"
          data-testid={testId ? `${testId}-text` : null}
        >
          <p>
            Ameriprise Financial <br />
            GET - On the App store
          </p>
        </div>
        <div className="ios-banner-grid-col link">
          <button
            onClick={() => window.open(mobileUrl, '_blank')}
            type="button"
          >
            Open
          </button>
        </div>
      </div>
    </div>
  )
}

IosBanner.propTypes = {
  show: PropTypes.oneOf(['always'])
}

export default IosBanner
