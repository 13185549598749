import React from 'react'
import xss from 'xss'
import './hero-topic-front.less'
import PropTypes from 'prop-types'
import Spacer from '../Spacer'

const HeroTopicFront = props => {
  const { content, testId } = props
  const {
    heading,
    body,
    image,
    componentSpacing,
    textAlignment,
    color,
    buttonAlignment,
    buttonHref,
    buttonTarget,
    buttonText,
    analytics
  } = content

  const largeFacet = `${window.AMPCOMPATH}images/facets/hero-facets-${
    color || 'midnight-blue'
  }.svg`
  const mobileFacet = `${window.AMPCOMPATH}images/facets/hero-facets-mobile-${
    color || 'midnight-blue'
  }.svg`

  let bgClassName = ''
  switch (color) {
  case 'light-blue':
    bgClassName = 'u-bgColorLightBlue'
    break
  case 'purple':
    bgClassName = 'u-bgColorPurple'
    break
  case 'berry':
    bgClassName = 'u-bgColorBerry'
    break
  case 'mint':
    bgClassName = 'u-bgColorMint'
    break
  case 'dark-mint':
    bgClassName = 'u-bgColorDarkMint'
    break
  case 'orange':
    bgClassName = 'u-bgColorOrange'
    break
  case 'dark-grey':
    bgClassName = 'u-bgColorDarkGrey'
    break
  case 'midnight-blue':
  default:
    bgClassName = 'u-bgColorMidnightBlue'
  }

  return (
    <Spacer spacing={componentSpacing}>
      <section
        className="HeroTopicFront--redesign u-paddingBottom2gu"
        data-analytics-container="Hero"
      >
        <div className="u-posRelative">
          <img
            className="HeroTopicFront-bgImage u-posAbsolute u-sm-hidden"
            src={largeFacet}
            alt=""
            data-testid={testId ? `${testId}-facet` : null}
          />
          <img
            className="HeroTopicFront-bgImage u-posAbsolute u-md-hidden u-lg-hidden"
            src={mobileFacet}
            alt=""
            data-testid={testId ? `${testId}-mobile-facet` : null}
          />
          <div
            className={`HeroTopicFront-contentBox u-flex u-sizeConstrained u-posRelative u-paddingHoriz10gu u-paddingVert10gu`}
          >
            <div
              className={`u-lg-size1of2 u-md-size1of2 u-sm-sizeFill Content u-flex u-flexAlignItemsCenter ${textAlignment} ${bgClassName}`}
              data-testid={testId ? `${testId}-box` : null}
            >
              <div className="u-paddingHoriz4gu u-paddingVert6gu">
                <h1
                  className="Type-1 u-textSecondary u-textColorWhite"
                  data-testid={testId ? `${testId}-heading` : null}
                  dangerouslySetInnerHTML={{ __html: xss(heading) }}
                ></h1>
                <div
                  className={`Content u-textColorWhite u-marginTop2gu ${textAlignment}`}
                  dangerouslySetInnerHTML={{ __html: xss(body) }}
                  data-testid={testId ? `${testId}-body` : null}
                />
                {buttonText && (
                  <a
                    className={`Button Button--primary Button--reversed u-marginTop5gu ${buttonAlignment}`}
                    href={buttonHref}
                    target={buttonTarget}
                    data-analytics={analytics}
                  >
                    {buttonText}
                  </a>
                )}
              </div>
            </div>
            <div className="HeroTopicFront-contentImage u-sm-hidden u-md-size2of3 u-lg-size2of3 u-posRelative">
              <img
                className="u-sizeFull"
                src={image}
                alt=""
                data-testid={testId ? `${testId}-image` : null}
              />
            </div>
          </div>
        </div>
      </section>
    </Spacer>
  )
}

HeroTopicFront.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.string,
    textAlignment: PropTypes.string,
    color: PropTypes.oneOf([
      'midnight-blue',
      'light-blue',
      'purple',
      'berry',
      'mint',
      'dark-mint',
      'orange',
      'dark-grey'
    ]),
    componentSpacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace']),
    buttonAlignment: PropTypes.string,
    buttonHref: PropTypes.string,
    buttonTarget: PropTypes.string,
    buttonText: PropTypes.string,
    analytics: PropTypes.string
  }),
  testId: PropTypes.string
}

export default HeroTopicFront
