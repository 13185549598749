/**
 * Empty the given element
 *
 * @param {Element} Element to empty
 * @returns {Element} The empty element
 */
export function empty (el) {
  while (el.lastChild) {
    delete el.removeChild(el.lastChild)
  }
  return el
}
