import React from 'react'
import PropTypes from 'prop-types'
import '../views/index.css'

/*
 *  Generic Components
 */
import StatesData from '../views/usa-states.json'
import InputField from '../views/Input'
import SelectBox from '../views/Selectbox'
import CheckboxList from '../views/CheckboxList'
import SuccessMessage from '../views/SuccessMessage'
import { ErrorMessageComponent, PleaseNoteComponent, SendEmailButtonComponent } from '../views/StatelessComponent'
import { ccpa } from '../views/Constants'
import RadioGroup from '../views/RadioGroup'

/*
 * Helper functions
 */
import { isValidEmail, isValidZipcode, formatPhoneNumber } from '../../../common/utils'
import { formSelectedData, CONSTs } from '../views/utils'

/*
 * CCPA Form component
 */
export default class ContactFormCCPA extends React.Component {
  constructor (props) {
    super(props)
    this.isClient = ccpa.clientid.yes
    this.userStatus = document.cookie.replace(/(?:(?:^|.*;\s*)lastAmpAuthState\s*=\s*([^;]*).*$)|^.*$/, '$1') || 'unrecognized'
    if (this.userStatus === 'unrecognized') {
      this.isClient = ccpa.clientid.no
    }
    this.state = {
      showServiceFailedMessage: false,
      showSuccessMessage: false,
      loading: false,
      caResident: null,
      formData: {
        resident: null,
        relationship: [],
        requesttype: [],
        firstname: null,
        lastname: null,
        clientid: this.isClient,
        emailaddress: null,
        street: null,
        city: null,
        state: null,
        zipcode: null,
        phone: null
      },
      errorFields: {
        resident: false,
        relationship: false,
        requesttype: false,
        firstname: false,
        lastname: false,
        clientid: false,
        emailaddress: false,
        state: false,
        city: false,
        street: false,
        zipcode: false,
        phone: false,
        emailWrongFormat: false
      }
    }
    /*
     * React refs to access the component
     */
    this.residentRef = React.createRef()
    this.secureSiteRef = React.createRef()
    this.relationshipRef = React.createRef()
    this.requesttypeRef = React.createRef()
    this.accountidRef = React.createRef()
    this.employeeidRef = React.createRef()
    this.firstnameRef = React.createRef()
    this.lastnameRef = React.createRef()
    this.emailaddressRef = React.createRef()
    this.streetRef = React.createRef()
    this.cityRef = React.createRef()
    this.stateRef = React.createRef()
    this.zipcodeRef = React.createRef()
    this.phoneRef = React.createRef()
    /*
     * Binding this(current object) keyword with the methods
     */
    this.submitForm = this.submitData.bind(this)
    this.changeState = this.changeState.bind(this)
    this.getFormStatus = this.getFormStatus.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
    this.showServerResponse = this.showServerResponse.bind(this)
    this.setEmailErrorMessage = this.setEmailErrorMessage.bind(this)
  }

  /*
   * Show service response
   */
  showServerResponse (props, message) {
    if (props && message) {
      if (message === CONSTs.SUCCESS) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: true,
            showServiceFailedMessage: false
          }
        )
      } else if (message === CONSTs.FAILURE) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      } else {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      }
    }
    return true
  }
  /*
   * Form status: valid or invalid
   */
  getFormStatus () {
    const {
      formData
    } = this.state

    let isFormValid = true
    for (let props in formData) {
      if (
        props !== 'clientid' &&
        formData[props] === null
      ) {
        isFormValid = false
      }
    }
    return isFormValid
  }

  changeState (event) {
    // To do: Update the data
  }

  /*
   * Render Buttons
   */
  renderSubmitButton () {
    const {
      props: {
        content: {
          formLabels: {
            buttonlabel,
            buttonLoadingLabel
          }
        }
      },
      state: {
        loading
      }
    } = this
    return (
      <SendEmailButtonComponent
        callback={this.submitForm}
        isLoading={loading}
        defaultLabel={buttonlabel}
        loadingLabel={buttonLoadingLabel}
      />
    )
  }

  /*
   * Validate the form fields
   */
  validateField (fieldName, value) {
    // To do: update the newly added fields
    let residentValid = this.state.errorFields.resident
    let relationshipValid = this.state.errorFields.relationship
    let requesttypeValid = this.state.errorFields.requesttype
    let emailValid = this.state.errorFields.emailaddress
    let firstnameValid = this.state.errorFields.firstname
    let lastnameValid = this.state.errorFields.lastname
    let streetValid = this.state.errorFields.street
    let cityValid = this.state.errorFields.city
    let phoneValid = this.state.errorFields.phone
    let zipcodeValid = this.state.errorFields.zipcode
    let stateValid = this.state.errorFields.state
    let fieldValues = this.state.formData
    let fieldErrors = this.state.errorFields
    const phoneRefNode = this.phoneRef.current

    switch (fieldName) {
    case 'resident':
      residentValid = this.state.formData.resident
      fieldValues.resident = residentValid ? value : null
      fieldErrors.resident = !residentValid
      break
    case 'requesttype':
      requesttypeValid = this.state.formData.requesttype.length
      fieldValues.requesttype = requesttypeValid ? value : []
      fieldErrors.requesttype = !requesttypeValid
      break
    case 'relationship':
      relationshipValid = this.state.formData.relationship.length
      fieldValues.relationship = relationshipValid ? value : []
      fieldErrors.relationship = !relationshipValid
      break
    case 'firstname':
      firstnameValid = value !== '' || value.length > 0
      fieldValues.firstname = firstnameValid ? value : null
      fieldErrors.firstname = !firstnameValid
      break
    case 'lastname':
      lastnameValid = value !== '' || value.length > 0
      fieldValues.lastname = lastnameValid ? value : null
      fieldErrors.lastname = !lastnameValid
      break
    case 'emailaddress':
      emailValid = isValidEmail(value)
      fieldValues.emailaddress = emailValid ? value : null
      fieldErrors.emailaddress = !emailValid
      fieldErrors.emailWrongFormat = false
      const formEmailAddress = this.emailaddressRef.current.value
      if (formEmailAddress && formEmailAddress.length > 0) {
        fieldErrors.emailWrongFormat = true
      }
      break
    case 'street':
      streetValid = value !== '' || value.length > 0
      fieldValues.street = streetValid ? value : null
      fieldErrors.street = !streetValid
      break
    case 'city':
      cityValid = value !== '' || value.length > 0
      fieldValues.city = cityValid ? value : null
      fieldErrors.city = !cityValid
      break
    case 'phone':
      const phoneNumOnly = value.replace(/\D/g, '')
      phoneValid = phoneNumOnly.length > 0
      if (phoneValid) {
        if (phoneNumOnly.length < 10) {
          fieldErrors.phone = true
          fieldValues.phone = null
        } else if (phoneNumOnly.length === 10) {
          fieldErrors.phone = false
          phoneRefNode.value = formatPhoneNumber(phoneRefNode.value)
          fieldValues.phone = phoneRefNode.value
        } else {
          phoneRefNode.value = formatPhoneNumber(phoneRefNode.value).substring(0, 14)
          fieldValues.phone = phoneRefNode.value
          fieldErrors.phone = false
        }
      } else {
        fieldErrors.phone = true
        fieldValues.phone = null
      }
      break
    case 'zipcode':
      zipcodeValid = isValidZipcode(value)
      fieldValues.zipcode = zipcodeValid ? value : null
      fieldErrors.zipcode = !zipcodeValid
      break
    case 'state':
      stateValid = value !== '' || value.length > 0
      fieldValues.state = stateValid ? value : null
      fieldErrors.state = !stateValid
      break
    default:
      fieldValues[fieldName] = value
      break
    }
    if (fieldName && value) {
      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
  }
  handleOptionSelected = (option) => {
    if (option) {
      this.setState({
        formData: {
          ...this.state.formData,
          resident: option
        }
      }, () => {
        this.validateField('resident', option)
      })
    }
  }

  handleOptionalRadio = (option) => {
    option && this.setState({
      formData: {
        ...this.state.formData,
        secureSite: option
      }
    })
  }

  handleMultipleOptions = (options) => {
    const groupName = options[0] && options[0].group
    let optionArray = []

    options.map((option) => {
      option.checked && option.value && optionArray.push(option.value)
    })

    if (optionArray.length) {
      this.setState({
        formData: {
          ...this.state.formData,
          [groupName]: optionArray
        }
      }, () => {
        this.validateField(groupName, optionArray)
      })
    }
  }
  handleFieldChange (event) {
    const name = event.target.name
    const value = event.target.value
    event.preventDefault()
    if (event.target.type !== 'radio' && event.target.type !== 'checkbox') {
      this.setState({
        [name]: value
      }, () => {
        this.validateField(name, value)
      })
      if (event.keyCode === 13) {
        this.validateField(name, value)
      }
    }
  }
  /*
   * Form Submission
   */

  // TODO add support for error message on failed callback.

  submitData (event) {
    event.preventDefault()
    const {
      props: { callback, content: { formEmailInfo, formTemplate } },
      state: { formData }
    } = this

    const canFormBeSubmitted = this.getFormStatus()
    if (canFormBeSubmitted === true) {
      // To do: Submit the form
      // Get the data to send email
      this.setState({ loading: true })
      const items = formSelectedData(formData, formTemplate)
      if (callback && typeof callback === 'function' && items) {
        return callback(items, formEmailInfo, this.showServerResponse)
      }
      return true
    } else {
      // errorFields
      const formResident = this.state.formData.resident
      const formRelationship = this.state.formData.relationship
      const formRequesttype = this.state.formData.requesttype
      const formFirstName = this.firstnameRef.current.value
      const formLastName = this.lastnameRef.current.value
      const formEmailAddress = this.emailaddressRef.current.value
      let formPhoneNumber = this.phoneRef.current.value
      const formStreet = this.streetRef.current.value
      const formCity = this.cityRef.current.value
      const formState = this.stateRef.current.value
      let formZipCode = this.zipcodeRef.current.value
      let fieldValues = this.state.formData
      let fieldErrors = this.state.errorFields
      // CA resident validation
      if (!formResident) {
        fieldValues.resident = null
        fieldErrors.resident = true
      } else {
        fieldValues.resident = this.state.formData.resident
        fieldErrors.resident = false
      }
      // relationship validation
      if (formRelationship && !formRelationship.length) {
        fieldValues.relationship = []
        fieldErrors.relationship = true
      } else {
        fieldValues.relationship = this.state.formData.relationship
        fieldErrors.relationship = false
      }
      // requesttype validation
      if (formRequesttype && !formRequesttype.length) {
        fieldValues.requesttype = []
        fieldErrors.requesttype = true
      } else {
        fieldValues.requesttype = this.state.formData.requesttype
        fieldErrors.requesttype = false
      }
      // FirstName validation
      if (formFirstName.length < 1) {
        fieldValues.firstname = null
        fieldErrors.firstname = true
      } else {
        fieldValues.firstname = this.state.formData.firstname
        fieldErrors.firstname = false
      }
      // LastName validation
      if (formLastName.length < 1) {
        fieldValues.lastname = null
        fieldErrors.lastname = true
      } else {
        fieldValues.lastname = this.state.formData.lastname
        fieldErrors.lastname = false
      }
      // Email validation
      if (formEmailAddress || !formEmailAddress.length) {
        const cleanedText = formEmailAddress.toLowerCase()
        fieldValues.emailaddress = isValidEmail(cleanedText) ? formEmailAddress : null
        fieldErrors.emailaddress = !fieldValues.emailaddress
      }
      // Street validation
      if (formStreet.length < 1) {
        fieldValues.street = null
        fieldErrors.street = true
      } else {
        fieldValues.street = this.state.formData.street
        fieldErrors.street = false
      }
      // City validation
      if (formCity.length < 1) {
        fieldValues.city = null
        fieldErrors.city = true
      } else {
        fieldValues.city = this.state.formData.city
        fieldErrors.city = false
      }
      // Zipcode Validation
      if (!formZipCode || formZipCode.length > 0) {
        fieldValues.zipcode = isValidZipcode(formZipCode) ? formZipCode : null
        fieldErrors.zipcode = !isValidZipcode(formZipCode)
      }
      // State Validation
      if (formState.length < 1) {
        fieldValues.state = null
        fieldErrors.state = true
      } else {
        fieldValues.state = this.state.formData.state
        fieldErrors.state = false
      }
      // Phone number validation
      if (formPhoneNumber || !formPhoneNumber.length) {
        if (formPhoneNumber.length < 10 || (formPhoneNumber.length > 10 && formPhoneNumber.length < 14)) {
          fieldValues.phone = null
          fieldErrors.phone = true
        } else if (formPhoneNumber.length === 14) {
          fieldErrors.phone = false
          fieldValues.phone = this.state.formData.phone
        } else {
          fieldErrors.phone = true
          fieldValues.phone = null
        }
      }

      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }

    return false
  }
  /*
   * Set the error message for email address
   */
  setEmailErrorMessage (wrongEmail, message) {
    if (wrongEmail) {
      return message.errorEmailaddressWrongFormat
    } else {
      return message.errorEmailaddress
    }
  }

  render () {
    const relationshipData = [
      {
        group: 'relationship',
        value: 'Applicant',
        slug: 'Applicant',
        checked: false
      },
      {
        group: 'relationship',
        value: 'Employee',
        slug: 'Employee',
        checked: false
      },
      {
        group: 'relationship',
        value: 'Client',
        slug: 'Client',
        checked: false
      },
      {
        group: 'relationship',
        value: 'Prospective Client',
        slug: 'Prospective Client',
        checked: false
      },
      {
        group: 'relationship',
        value: 'Other',
        slug: 'Other',
        checked: false
      }
    ]

    const requestTypeData = [
      {
        group: 'requesttype',
        value: 'Access Data',
        slug: 'AccessData',
        checked: false
      },
      {
        group: 'requesttype',
        value: 'Delete Data',
        slug: 'DeleteData',
        checked: false
      },
      {
        group: 'requesttype',
        value: 'Correct Data',
        slug: 'CorrectData',
        checked: false
      }
    ]

    const {
      props: {
        name,
        content: {
          formErrorMessages,
          formPleaseNote,
          serviceMessages,
          formHeading,
          formLabels
        }
      },
      state: {
        showServiceFailedMessage,
        showSuccessMessage,
        errorFields,
        formData
      }
    } = this
    return (
      <div className='u-marginTop6gu u-adel-reset'>
        {
          showSuccessMessage &&
          serviceMessages &&
          serviceMessages.serviceSuccess &&
          <SuccessMessage
            thankYou={serviceMessages.serviceSuccessThankyou}
            message={serviceMessages.serviceSuccess}
          />
        }
        {
          !showSuccessMessage &&
          <div className='u-sizeConstrained'>
            {/* <ul>{formErrorMessages && formErrorMessages.map(item => <li>item</li>)}</ul> */}
            <form
              name={ name }
              className='u-marginBottom5gu u-size5of12 u-md-size8of12 u-sm-sizeFull Form'
              onBlur={ (e) => { this.handleFieldChange(e) } }
              onKeyUp={ (e) => { this.handleFieldChange(e) } }
            >
              {
                formHeading &&
                formHeading.title &&
                  <h2 className='u-marginBottom6gu'>{ formHeading.title }</h2>
              }
              <div>
                <label className='Form-label'>Are you a California resident?</label>
                <div className='Radio Form-group'>
                  <RadioGroup
                    error={errorFields.resident}
                    message={'Please select one option.'}
                    options={['Yes', 'No']}
                    reference={this.residentRef}
                    name='resident'
                    onOptionSelected={this.handleOptionSelected}
                  />
                  <br />
                </div>
                {formData.resident && formData.resident.toLowerCase() === 'no' && <> <p className="Form-help">This right applies only to CA residents</p></>}
              </div>
              <div className='u-marginTop6gu'>
                <label className='Input-label'>Please select the relationship(s) you have with us.</label>
                <CheckboxList
                  name='relationship'
                  id='relationship'
                  label={formLabels.relationship}
                  reference={this.relationshipRef}
                  options={relationshipData}
                  onOptionsSelected={this.handleMultipleOptions}
                  error={errorFields.relationship}
                  message={formErrorMessages.errorRelationship}
                />
              </div>
              <div className='u-marginTop6gu'>
                <label className='Form-label'>Select the request type(s).</label>
                <CheckboxList
                  name='requesttype'
                  id='requesttype'
                  label={formLabels.requesttype}
                  reference={this.requesttypeRef}
                  options={requestTypeData}
                  onOptionsSelected={this.handleMultipleOptions}
                  error={errorFields.requesttype}
                  message={formErrorMessages.errorRequesttype}
                />
              </div>
              <div className='u-marginTop6gu'></div>
              <InputField
                name='firstname'
                id='firstname'
                placeholder=''
                label={formLabels.firstname}
                reference={this.firstnameRef}
                error={errorFields.firstname}
                message={formErrorMessages.errorFirstname}
              />
              <InputField
                name='lastname'
                id='lastname'
                placeholder=''
                label={formLabels.lastname}
                reference={this.lastnameRef}
                error={errorFields.lastname}
                message={formErrorMessages.errorLastname}
              />
              <InputField
                name='emailaddress'
                id='email'
                placeholder=''
                label={formLabels.emailaddress}
                reference={this.emailaddressRef}
                error={errorFields.emailaddress}
                message={ this.setEmailErrorMessage(errorFields.emailWrongFormat, formErrorMessages) }
              />
              <InputField
                name='phone'
                id='phone'
                placeholder=''
                label={formLabels.phone}
                reference={this.phoneRef}
                error={errorFields.phone}
                message={formErrorMessages.errorPhone}
              />
              <InputField
                name='street'
                id='street'
                placeholder=''
                label={formLabels.street}
                reference={this.streetRef}
                error={errorFields.street}
                message={formErrorMessages.errorStreet}
              />
              <InputField
                name='city'
                id='city'
                placeholder=''
                label={formLabels.city}
                reference={this.cityRef}
                error={errorFields.city}
                message={formErrorMessages.errorCity}
              />
              <SelectBox
                name='state'
                id='state'
                placeholder=''
                label={formLabels.state}
                reference={this.stateRef}
                callback={this.changeState}
                data={StatesData}
                error={errorFields.state}
                message={formErrorMessages.errorState}
              />
              <InputField
                name='zipcode'
                id='zipcode'
                placeholder=''
                required={true}
                label={formLabels.zipcode}
                reference={this.zipcodeRef}
                error={errorFields.zipcode}
                message={formErrorMessages.errorZipcode}
              />
              <div>
                <label className='Form-label'>If you are a client, are you registered on the Secure Site? (optional)</label>
                <div className='Radio Form-group'>
                  <RadioGroup
                    options={['Yes', 'No']}
                    reference={this.secureSiteRef}
                    name='secureSite'
                    onOptionSelected={this.handleOptionalRadio} />
                </div>
              </div>
              <div className='u-marginTop6gu'></div>
              <InputField
                name='accountid'
                id='accountid'
                placeholder=''
                label={formLabels.accountid}
                reference={this.accountidRef}
              />
              <div className='u-marginTop6gu'></div>
              <InputField
                name='employeeid'
                id='employeeid'
                placeholder=''
                label={formLabels.employeeid}
                reference={this.employeeidRef}
              />
              <div className='u-marginBottom6gu'></div>
              {
                showServiceFailedMessage &&
                serviceMessages.serviceFailed &&
                <ErrorMessageComponent
                  message={serviceMessages.serviceFailed}
                />
              }
              {
                this.renderSubmitButton()
              }
            </form>
            {
              formPleaseNote &&
              formPleaseNote.message &&
              <PleaseNoteComponent
                content={formPleaseNote.message}
              />
            }
          </div>

        }
      </div>
    )
  }
}

/*
 * Form default Proptypes
 */
const propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.object
}

ContactFormCCPA.propTypes = propTypes
