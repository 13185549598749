import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Card from '../Card/Card'

const CardCarousel = props => {
  const { componentSpacing, cardList, cardGridHeadline } = props
  const [position, setPosition] = useState(0)
  const [positionOffset, setPositionOffset] = useState(0)
  const [cardWidth, setCardWidth] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const wrapperRef = useRef(null)
  const [startY, setStartY] = useState(0)
  const [startX, setStartX] = useState(0)
  const CARD_GAP = 20

  const moveLeft = useCallback(
    jumpAmount => {
      if (position === 0) {
        return
      }
      if (isNaN(jumpAmount) || jumpAmount === 0) {
        setPosition(position - 1)
        return
      }
      if (position - jumpAmount <= 0) {
        setPosition(0)
        return
      }
      setPosition(position - jumpAmount)
    },
    [position]
  )
  const moveRight = useCallback(
    jumpAmount => {
      if (position === cardList.length - 1) {
        return
      }
      if (isNaN(jumpAmount) || jumpAmount === 0) {
        setPosition(position + 1)
        return
      }
      if (position - jumpAmount >= cardList.length) {
        setPosition(cardList.length - 1)
        return
      }
      setPosition(position - jumpAmount)
    },
    [position, cardList.length]
  )
  const onSwipe = useCallback(
    (deltaX, deltaY) => {
      if (Math.abs(deltaY) > 100) {
        return
      }
      const numCardsMoved = deltaX / (cardWidth + CARD_GAP)
      if (deltaX > 50) {
        moveLeft(Math.floor(numCardsMoved))
      }
      if (deltaX < -50) {
        moveRight(Math.ceil(numCardsMoved))
      }
    },
    [moveLeft, moveRight, cardWidth]
  )
  const handleTouchStart = useCallback(e => {
    if (!wrapperRef.current.contains(e.target)) {
      return
    }
    e.preventDefault()
    setIsSwiping(true)
    setStartX(e.touches[0].clientX)
    setStartY(e.touches[0].clientY)
  }, [])

  const handleTouch = useCallback(
    e => {
      if (!wrapperRef.current.contains(e.target)) {
        return
      }
      e.preventDefault()
      const endX = e.changedTouches[0].clientX
      const deltaX = endX - startX
      setPositionOffset(deltaX)
    },
    [startX, setPositionOffset]
  )

  const handleTouchEnd = useCallback(
    e => {
      if (!wrapperRef.current.contains(e.target)) {
        return
      }
      e.preventDefault()
      const endX = e.changedTouches[0].clientX
      const endY = e.changedTouches[0].clientY
      const deltaX = endX - startX
      const deltaY = endY - startY
      onSwipe(deltaX, deltaY)
      setPositionOffset(0)
      setIsSwiping(false)
    },
    [startX, startY, onSwipe]
  )

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouch)
    window.addEventListener('touchend', handleTouchEnd)
    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouch)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [handleTouchStart, handleTouchEnd, handleTouch])

  const handleWindowSizeChange = () => {
    if (wrapperRef && wrapperRef.current) {
      const size = wrapperRef.current.getBoundingClientRect()
      if (size.width + 60 < 768) {
        setCardWidth(size.width - 20)
        return
      }
      if (size.width + 60 < 1240) {
        setCardWidth((size.width - 60) / 3)
        return
      }
      setCardWidth((size.width - 40) / 3)
    }
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const gridColumnWidth = `${cardWidth}px`
  const gridStyle = {
    gridTemplateColumns: `repeat(${cardList.length}, ${gridColumnWidth})`,
    right: position * (cardWidth + CARD_GAP) - positionOffset
  }

  return (
    <section className={`card-carousel-component ${componentSpacing}`}>
      {cardGridHeadline && (
        <div className="Grid u-sizeConstrained u-posRelative">
          <h2 className="u-textColorDefault">{cardGridHeadline}</h2>
        </div>
      )}
      <div
        className={`carousel-CardCarouselContainer--overflowWrapper u-paddingLeft6gu u-paddingRight8gu ${
          position !== 0
            ? 'shifted u-paddingLeft8gu u-sm-paddingLeft8gu u-md-paddingLeft8gu '
            : ''
        } u-sizeFull u-sm-paddingRight8gu u-md-paddingRight8gu u-marginBottom1gu`}
      >
        <div
          className={`card-list-wrapper u-posRelative u-marginVert5gu${
            isSwiping ? ' card-list-wrapper--noTransition' : ''
          }`}
          style={gridStyle}
          ref={wrapperRef}
          data-testid="card-list-wrapper"
        >
          {cardList.map((card, index) => (
            <Card key={index} card={card} onFocus={() => setPosition(index)} />
          ))}
        </div>
      </div>
      <div className="card-carousel-controls u-flex u-flexAlignItemsEnd u-sizeConstrained u-marginBottom1gu u-posRelative">
        <button
          className="Button Button--secondary Button--secondary--action-blue Button--icon Button--icon--stroke"
          onClick={moveLeft}
          disabled={position === 0}
        >
          <span aria-hidden="true">&#xe314;</span>
          <span className="u-hiddenVisually">Slide Left</span>
        </button>
        <button
          className="Button Button--secondary Button--secondary--action-blue Button--icon Button--icon--stroke"
          onClick={moveRight}
          disabled={position === cardList.length - 1}
        >
          <span aria-hidden="true">&#x25b9;</span>
          <span className="u-hiddenVisually">Slide Right</span>
        </button>
        <p className="u-textColorActionBlue">
          {position + 1} / {cardList.length}
        </p>
      </div>
    </section>
  )
}
CardCarousel.propTypes = {
  cardList: PropTypes.array,
  componentSpacing: PropTypes.string,
  cardGridHeadline: PropTypes.string
}
CardCarousel.defaultProps = {
  cardList: [],
  componentSpacing: '',
  cardGridHeadline: ''
}
export default CardCarousel
