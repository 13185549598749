import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './alert.css'

const Alert = props => {
  const { content, testId } = props
  const {
    icon,
    iconColor,
    body,
    bgColor,
    heading,
    linkHref,
    linkTarget,
    linkText,
    showCloseButton,
    analytics
  } = content

  const [isDismissed, setIsDismissed] = useState(false)
  function handleCloseButton () {
    setIsDismissed(true)
  }
  if (isDismissed) {
    return null
  }
  return (
    <section
      className={`Alert ${bgColor}`}
      data-testid={testId ? `${testId}` : null}
    >
      <div className="u-sizeConstrained u-paddingVert4gu u-sm-hidden u-md-hidden">
        <div className="u-flex u-flexAlignItemsCenter u-sm-flexCol u-sm-flexAlignItemsStart">
          {icon && (
            <svg
              className={`Alert-icon u-paddingRight2gu ${iconColor}`}
              aria-hidden="true"
              width="36px"
              height="36px"
              data-testid={testId ? `${testId}-icon` : null}
            >
              <use href={icon} />
            </svg>
          )}
          <h3
            className="u-textColorNeutralGrey u-flexShrink0"
            data-testid={testId ? `${testId}-heading` : null}
          >
            {heading}
          </h3>
          <div
            className="Content u-marginHoriz4gu u-textColorNeutralGrey u-sm-marginVert4gu"
            dangerouslySetInnerHTML={{ __html: body }}
            data-testid={testId ? `${testId}-body` : null}
          ></div>
          <div className="u-flexExpandLeft u-sm-marginLeft0 u-sm-marginTop4gu u-flexShrink0">
            {linkHref && (
              <a
                className="Alert-button Button Button--small Button--secondary Button--secondary--action-blue u-marginHoriz4gu"
                href={linkHref}
                target={linkTarget}
                data-analytics={analytics}
                data-testid={testId ? `${testId}-link` : null}
              >
                {linkText}
              </a>
            )}
            {showCloseButton && (
              <button
                aria-hidden="true"
                className="Alert-closeIcon Icon u-textSizeMsrp4"
                data-testid={testId ? `${testId}-close-button` : null}
                onClick={() => {
                  handleCloseButton()
                }}
              >
                &#x2297;
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="u-sizeConstrained u-paddingVert4gu u-lg-hidden">
        <div className="u-flex u-flexCol u-alignItemsBaseline">
          <div className="u-flex u-flexAlignItemsCenter">
            {icon && (
              <svg
                className={`Alert-icon u-paddingRight2gu ${iconColor}`}
                aria-hidden="true"
                width="36px"
                height="36px"
              >
                <use href={icon} />
              </svg>
            )}
            <h3 className="u-textColorNeutralGrey">{heading}</h3>
            {showCloseButton && (
              <button
                aria-hidden="true"
                className="Alert-closeIcon Icon u-flexExpandLeft u-textSizeMsrp4"
                onClick={() => {
                  handleCloseButton()
                }}
              >
                &#x2297;
              </button>
            )}
          </div>
          <div
            className="Content u-marginVert4gu u-textColorNeutralGrey"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
          {linkHref && (
            <a
              className="Alert-button Button Button--small Button--secondary Button--secondary--action-blue"
              href={linkHref}
              target={linkTarget}
              data-analytics={analytics}
            >
              {linkText}
            </a>
          )}
        </div>
      </div>
    </section>
  )
}
Alert.propTypes = {
  content: PropTypes.shape({
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    body: PropTypes.string,
    bgColor: PropTypes.oneOf([
      'u-bgColorStone',
      'u-bgColorPurpleLight',
      'u-bgColorLimeLight',
      'u-bgColorMintLight',
      'u-bgColorEstefan',
      'u-bgColorPurple'
    ]),
    heading: PropTypes.string,
    linkHref: PropTypes.string,
    linkTarget: PropTypes.string,
    linkText: PropTypes.string,
    showCloseButton: PropTypes.oneOf(['true', '']),
    analytics: PropTypes.string
  })
}
export default Alert
