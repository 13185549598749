import './index.less'
import {
  brightcovePlayerStyles,
  brightcoveVideoJsStyles
} from './brightcove-styles'
import { getScriptNonce } from '../../common/get-script-nonce'

const ACCOUNT_ID = '1625296066001'
const POLICY_KEY =
  'BCpkADawqM1nLERXcYvuNaMfVP554QRpsr5ion-p1V2EpxCxNTE0QbZ5aZjCQxOLX_IGu497FV65g9T_H1B6H3WKbTs-idPls2PEFsaoBLX_ogYjByJwheKEPv799tZ5HCoaDXNNVjInrn_B'
const SCRIPT_URL =
  '//players.brightcove.net/1625296066001/yH3eoPgbu_default/index.min.js'

const url = `https://edge.api.brightcove.com/playback/v1/accounts/${ACCOUNT_ID}`

const brightcoveStyleByClass = document.querySelector(
  '.bc-style-yH3eoPgbu-default'
)
const brightcoveStyleById = document.querySelector('#bc-style-vjs')

const addBrightcoveStyle = (elem, content) =>
  elem && (elem.textContent = content)

addBrightcoveStyle(brightcoveStyleByClass, brightcovePlayerStyles)
addBrightcoveStyle(brightcoveStyleById, brightcoveVideoJsStyles)

// Script loaded and identifier are global across instances of VideoComponent
let scriptLoaded = false
let identifier = 0

// eslint-disable-next-line no-undef
class VideoComponent extends HTMLElement {
  get autoplay () {
    return this.hasAttribute('autoplay')
  }

  get inline () {
    return this.hasAttribute('inline')
  }

  get playlistId () {
    return this.getAttribute('playlist-id')
  }

  get videoId () {
    return this.getAttribute('video-id')
  }

  connectedCallback () {
    this.videos = {}
    this.getVideoInfo()
      .then(playlist => {
        if (Array.isArray(playlist.videos)) {
          playlist.videos.forEach((video, idx) => {
            // Asynchronously create promises for each of the video players loading
            this.loadVideo(video, idx)
          })
        } else {
          // There's only one...
          this.loadVideo(playlist)
        }
      })
      .then(() => this.addPlayPauseToText())
      .then(_ => this.loadScript())
      .then(_ => {
        const videoContainers = this.querySelectorAll('.vc-video-container')
        let promises = []

        videoContainers.forEach(container => {
          const videoEl = container.querySelector('.video-js')
          promises.push(
            new Promise(resolve =>
              window.videojs(videoEl).ready(function () {
                resolve([this, container, videoEl])
              })
            )
          )
        })

        return Promise.all(promises)
      })
      .then(players => {
        players.forEach(([player, container, videoEl]) => {
          let image = document.createElement('img')
          let config = this.videos[player.id_]

          image.src = config.poster
          // image.addEventListener('load', _ => container.style.removeProperty('display'))
          image.addEventListener('load', _ =>
            container.classList.remove('u-hidden')
          )

          container.videoEl = videoEl
          container.player = player

          player.on(
            'play',
            this.playVideo.bind(this, player, container, videoEl)
          )
          player.on(
            'ended',
            this.endVideo.bind(this, player, container, videoEl)
          )
          player.on('pause', this.pauseVideo.bind(this, player, container))
        })
      })
  }

  pauseVideo (player, container) {
    container.classList.remove('currently-playing')
    container.classList.add('currently-paused')
    player.pause()
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches && container.classList.contains('inline')) {
      player.bigPlayButton.el_.style.setProperty('display', 'block')
    }
  }

  endVideo (player, container, videoEl) {
    player.autoplay(false)
    container.classList.remove('currently-playing')
    container.classList.remove('currently-paused')
    if (player.isFullscreen()) {
      player.exitFullscreen()
    }
    // return the video back to small size
    if (player.isInline) {
      container.classList.add('inline')
      videoEl.classList.add('inline')
    }
    player.posterImage.el_.style.setProperty('display', 'block')
    player.bigPlayButton.el_.style.setProperty('display', 'block')
    player.loadingSpinner.el_.style.setProperty('display', 'none')
    player.currentTime(0)
    player.pause()
  }

  getVideoInfo () {
    const videoResourceUrl = this.videoId
      ? `videos/${this.videoId}`
      : `playlists/${this.playlistId}`
    return window
      .fetch(`${url}/${videoResourceUrl}`, {
        headers: { accept: `application/json;pk=${POLICY_KEY}` },
        method: 'GET'
      })
      .then(response => response.json())
      .catch(error => console.error(`Error getVideoInfo`, error))
  }

  loadScript () {
    if (!scriptLoaded) {
      scriptLoaded = new Promise(resolve => {
        let script = document.createElement('script')
        script.src = SCRIPT_URL
        script.nonce = getScriptNonce()
        script.onload = resolve
        document.body.appendChild(script)
      })
    }
    return scriptLoaded
  }

  loadVideo (video, idx) {
    video.uniquePlayerId = this.uniqueId()

    for (const uid in this.videos) {
      // connectedCallback() may be called more than once
      if (video.id === this.videos[uid].id) {
        return null
      }
    }

    // NOTE: data-video-id supports both videos and playlists
    // https://support.brightcove.com/step-step-brightcove-player#embedinpage
    this.innerHTML += `
        <div id="${
          video.uniquePlayerId
        }-vc-video-container" class="vc-video-container ${
      this.inline ? 'inline' : ''
    } u-hidden">
            <div class="sixteen-by-nine-container">
                <video
                    class="video-js ${this.inline ? 'inline' : ''}"
                    controls
                    data-video-id=${video.id}
                    data-account="${ACCOUNT_ID}"
                    data-player="yH3eoPgbu"
                    data-embed="default"
                    data-application-id
                    data-analytics="video"
                    data-analytics-video-id="${video.id}"
                    id=${video.uniquePlayerId}>
                </video>
            </div>
            <div id="${
              video.uniquePlayerId
            }-text-target" class="video-description-container">
              <h4 
                class="video-name"
                data-analytics="video-title"
              >
                ${this.formatText(video.name)}
              </h4>
              <p data-analytics="video-description">
                  <span class="video-long-description">${this.formatText(
              video.long_description
            )}</span>
                  <span class="video-duration">${this.formatTime(
              video.duration
            )}</span>
              </p>
            </div>
        </div>
        `

    video.autoplay = this.autoplay && idx === 0
    this.videos[video.uniquePlayerId] = video
  }

  addPlayPauseToText () {
    Object.keys(this.videos).forEach(key => {
      const videoText = document.getElementById(`${key}-text-target`)
      videoText &&
        videoText.addEventListener('click', () => {
          const vid = document.getElementById(key).querySelector('video')
          const videoContainer = document.getElementById(
            `${key}-vc-video-container`
          )
          if (
            vid &&
            videoContainer &&
            videoContainer.classList.contains('currently-playing')
          ) {
            vid.pause()
          } else {
            vid.play()
          }
        })
    })
  }

  formatText (txt) {
    if (!txt) return ''
    return txt
      .replace(/\(TM\)/g, '<sup>&#8482;</sup>') // TM
      .replace(/\(r\)/g, '<sup>&#174;</sup>') // REGISTERED SIGN
  }

  formatTime (time) {
    const timeInSeconds = Math.floor(time / 1000)
    const minutes = Math.floor(timeInSeconds / 60)
    let seconds = Math.floor(timeInSeconds % 60)
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    return `(${minutes}:${seconds})`
  }

  playVideo (player, container, videoEl) {
    const qualityMenuDiv = document.getElementsByClassName(
      'vjs-quality-menu-wrapper'
    ) // const qualityMenuButton = document.querySelector('[title="Quality Levels"]')

    for (const qualityEl of qualityMenuDiv) {
      if (qualityEl.classList.contains('vjs-hidden')) {
        qualityEl.classList.remove('vjs-hidden')
        qualityEl.classList.add('vjs-menu-popup-qualityLevel')
      }
    }

    player.posterImage.el_.style.setProperty('display', 'none')
    player.bigPlayButton.el_.style.setProperty('display', 'none')
    // hide player control bar when video is small but show when video is played
    if (videoEl.classList.contains('inline')) {
      player.isInline = true
      videoEl.classList.remove('inline')
      container.classList.remove('inline')
    }
    document.querySelectorAll('.currently-playing').forEach(el => {
      el.classList.remove('currently-playing')
      if (el.player) {
        el.player.pause()
        el.player.bigPlayButton.el_.style.setProperty('display', 'block')
        el.player.posterImage.el_.style.setProperty('display', 'block')
      }
    })

    container.classList.add('currently-playing')
    container.classList.remove('currently-paused')
    this.handleControlbarMenus(player.id_, player)
    this.scrollTo(videoEl)
  }

  handleControlbarMenus (videoId, player) {
    player.loadingSpinner.el_.style.setProperty('display', '')

    let playbackMenu
    let playbackButton
    let qualityMenu
    let qualityButton
    let controlBar

    const videoList = document.getElementsByTagName('video-component')

    for (const singleVideo of videoList) {
      for (const videoItem of singleVideo.children) {
        if (videoItem.player.id_ === videoId) {
          for (const videoChild of videoItem.children) {
            if (videoChild.className === 'sixteen-by-nine-container') {
              for (const nextChild of videoChild.children) {
                if (nextChild.id === videoId) {
                  for (const childItem of nextChild.children) {
                    if (childItem.className === 'vjs-control-bar') {
                      controlBar = childItem
                      for (const controlChild of childItem.children) {
                        if (controlChild.innerText.includes('Playback Rate')) {
                          playbackButton = controlChild
                          for (const menuChild of controlChild.children) {
                            if (menuChild.className === 'vjs-menu') {
                              playbackMenu = menuChild
                            }
                          }
                        }

                        if (controlChild.innerText.includes('Quality Levels')) {
                          qualityButton = controlChild
                          for (const menuChild of controlChild.children) {
                            if (menuChild.className === 'vjs-menu') {
                              qualityMenu = menuChild
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // adding and removing events for popup menus

    /* eslint-disable no-unused-expressions */
    controlBar?.addEventListener('mouseenter', () => {
      playbackMenu.classList.remove('vjs-menu-popup-playback')
      qualityMenu.classList.remove('vjs-menu-popup-quality')
    })

    playbackButton?.addEventListener('mouseenter', () => {
      playbackMenu.classList.add('vjs-menu-popup-playback')
      qualityMenu.classList.remove('vjs-menu-popup-quality')
      qualityMenu.classList.remove('vjs-lock-showing')
    })

    qualityButton?.addEventListener('mouseenter', () => {
      playbackMenu.classList.remove('vjs-menu-popup-playback')
      qualityMenu.classList.add('vjs-menu-popup-quality')
      qualityMenu.classList.remove('vjs-lock-showing')
    })

    qualityButton?.addEventListener('click', () => {
      qualityMenu.classList.remove('vjs-lock-showing')
    })

    playbackMenu?.addEventListener('mouseleave', () => {
      playbackMenu.classList.remove('vjs-menu-popup-playback')
    })

    qualityMenu?.addEventListener('mouseleave', () => {
      qualityMenu.classList.remove('vjs-menu-popup-quality')
    })
    /* eslint-enable no-unused-expressions */
  }

  scrollTo (el) {
    let top = window.pageYOffset + el.getBoundingClientRect().top
    if (el.scrollHeight < window.innerHeight) {
      top -= (window.innerHeight = el.scrollHeight) / 2
    }
    window.scroll({
      behavior: 'smooth',
      scrollTop: top
    })
  }

  uniqueId () {
    let unique = 'video-' + Date.now() + ++identifier
    while (document.getElementById(unique)) {
      unique = 'video-' + Date.now() + ++identifier
    }
    return unique
  }
}

window.customElements.define('video-component', VideoComponent)
