function paginateCards (node, loadFn, queries) {
  if (!(node instanceof HTMLElement)) {
    return null
  }

  const LIMIT = parseInt(node.getAttribute('limit')) || 8
  const more = node.querySelector('.load-more')
  const button = node.querySelector('.load-more > button')
  const list = node.querySelector(queries.list)
  const cardCategoriesDropdown = node.querySelector('#cardCategories')
  const allInput = node.querySelector('input[all]')
  const allValue = allInput ? allInput.value : 'All'

  if (!list) {
    return null
  }

  const loadMore = () => {
    const query = queries.extra
    const extraCardsQuery = query(cardCategoriesDropdown)
    const extraCards = list.querySelectorAll(extraCardsQuery)
    const cardsUpToLimit = [...extraCards].slice(0, LIMIT)
    cardsUpToLimit.forEach(loadFn)

    if (extraCards.length <= LIMIT && more) {
      more.style.visibility = 'hidden'
      button.style.visibility = 'hidden'
    }
  }
  const dispatchClicks = evt => {
    if (evt.target === button) {
      loadMore()
    }
  }

  if (button) {
    node.addEventListener('click', dispatchClicks)
  }

  const checkedValue = cardCategoriesDropdown && cardCategoriesDropdown.querySelector('input:checked').value

  const showCardsQuery = (!checkedValue || checkedValue === allValue) ? '[card-category]' : `[card-category='${checkedValue}']`
  const filterConfig = { node: node, dropdown: cardCategoriesDropdown, limit: LIMIT, allValue: allValue }

  limitDisplayOfCards(filterConfig, showCardsQuery)
  determineLoadMoreDisplay(filterConfig, node.querySelectorAll(showCardsQuery))
  cardCategoriesDropdown && cardCategoriesDropdown.addEventListener('change', (event) => displayCategory(filterConfig, event))
}

function updateDropdownLabel (filterConfig) {
  const { node, dropdown } = filterConfig
  const checkedValue = dropdown.querySelector('input:checked').value
  const activeLabel = node.querySelector(`label[for='${checkedValue}'`)
  const dropdownLabelLink = node.querySelector('summary > span')
  const dropdownMenu = node.querySelector('details')
  dropdownLabelLink.innerText = activeLabel.innerText
  dropdownMenu.removeAttribute('open')
}

export function displayCategory (filterConfig, event) {
  const { node, allValue } = filterConfig

  const category = event.target.value
  let cardCategoryQuery = '[card-category]'
  if (category !== allValue) {
    cardCategoryQuery = `[card-category='${category}']`
    const cardsToHide = node.querySelectorAll(`[card-category]:not(${cardCategoryQuery})`)
    cardsToHide.forEach(card => card.setAttribute('hidden', ''))
  }
  const cardsToDisplay = node.querySelectorAll(cardCategoryQuery)
  cardsToDisplay.forEach(card => card.removeAttribute('hidden'))

  updateDropdownLabel(filterConfig)
  limitDisplayOfCards(filterConfig, cardCategoryQuery)
  determineLoadMoreDisplay(filterConfig, cardsToDisplay)
}

export function determineLoadMoreDisplay (filterConfig, cardsToSelect) {
  const { node, limit } = filterConfig

  const more = node.querySelector('.load-more')
  const button = node.querySelector('.load-more > button')
  if (cardsToSelect.length > limit) {
    more.style.removeProperty('visibility')
    button.style.removeProperty('visibility')
  } else {
    if (more && button) {
      more.style.visibility = 'hidden'
      button.style.visibility = 'hidden'
    }
  }
}

export function limitDisplayOfCards (filterConfig, query) {
  const { node, limit } = filterConfig

  const filteredCards = node.querySelectorAll(query)
  const cardsToHide = Array.from(filteredCards).slice(limit)
  cardsToHide.forEach(card => card.setAttribute('hidden', ''))
}

export function queryForCards (dropdown) {
  let query = '.Card[hidden]'
  if (dropdown) {
    const allInput = dropdown.querySelector('input[all]')
    const allValue = allInput ? allInput.value : 'All'
    const checkedValue = dropdown.querySelector('input:checked').value
    if (dropdown) {
      query = checkedValue === allValue ? '.Card[hidden]' : `.Card[hidden][card-category='${checkedValue}']`
    }
  }
  return query
}

export function startCardList (node) {
  const fn = card => {
    card.hidden = false
  }

  return paginateCards(node, fn, { list: '.card-list', extra: queryForCards })
}

export function startCardGrid (node) {
  const fn = card => {
    card.classList.remove('collapse')
    card.removeAttribute('hidden')
  }
  return paginateCards(node, fn, { list: '.Grid', extra: () => '.collapse' })
}
