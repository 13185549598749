const startEval = (callback = () => {}) => {
  const promo = document.querySelector('.start-eval')
  if (promo) {
    const promoBtn = promo.querySelector('#amp-ira-rollover-eval-get-started')
    const slideShow = document.querySelector(
      '.swiper-container.rollover-swiper'
    )
    const aboutLink = document.querySelector('.about-link')
    promoBtn.addEventListener('click', e => {
      e.preventDefault()
      promo.style.display = 'none'
      slideShow.style.display = 'block'
      aboutLink.style.display = 'block'
      const direction = 'start'
      callback(direction)
    })
    // set the previous swiper to go back to landing page if on question one
    document
      .querySelector('.swiper-button-prev')
      .addEventListener('click', () => {
        if (document.rollover.state === 1) {
          promo.style.display = 'block'
          slideShow.style.display = 'none'
          aboutLink.style.display = 'none'
        } else {
          const direction = 'prev'
          callback(direction)
        }
      })
  }
}

export { startEval }
