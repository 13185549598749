import React from 'react'
import PropTypes from 'prop-types'

const LoginForm = props => {
  const { bgColor, isExpanded } = props

  return (
    <div
      className="LoginClient u-sm-hidden u-md-hidden"
      data-analytics-container="Login"
    >
      <div
        className="login-placeholder"
        data-orientation="horizontal"
        data-bg-color={bgColor}
        data-reversed-color={bgColor !== '#F3F2F1' ? 'true' : 'false'}
        data-expanded={isExpanded}
      ></div>
    </div>
  )
}

LoginForm.propTypes = {
  bgColor: PropTypes.string, // hex value
  isExpanded: PropTypes.string
}

export default LoginForm
