import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Tab extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      isActive: this.props.isActive
    }

    this.onClick = this.onClick.bind(this)
    this.onPressKey = this.onPressKey.bind(this)

    // need reference to DOM for focus()
    this.elRef = React.createRef()
  }
  // Event Handlers

  onClick () {
    const { index, onClick } = this.props
    onClick(index)
  }

  onPressKey (event) {
    const { onKeyPress, index } = this.props
    onKeyPress(event.keyCode, index)
  }

  // Lifecycle Hooks

  componentDidUpdate () {
    // shift focus to the selected tab
    if (this.props.isActive) {
      this.elRef.current.focus()
    }
  }

  // Render

  render () {
    const {
      onClick,
      onPressKey,
      props: {
        isActive,
        label
      }
    } = this

    const activeClass = classNames({
      'tabList-tab-content u-paddingVert2gu': true,
      'u-textColorBrandLight': isActive
    })

    return (
      <li
        ref={this.elRef}
        className={`tabList-tab animate-tab`}
        onClick={onClick}
        onKeyDown={onPressKey}
        tabIndex={isActive ? 0 : -1}
        role={'tab'}
        aria-selected={isActive}
      >
        <div
          className={activeClass}
          dangerouslySetInnerHTML={{ __html: label }}></div>
      </li>
    )
  }
}
