/*
 * Personalization data retrieval
 */

const CONSTs = {
  'storageName': 'clientPageVisitInfo'
}

const getPersonlizedStorage = () => {
  if (window.localStorage) {
    const retrievedData = window.localStorage.getItem(CONSTs.storageName)
    return retrievedData ? JSON.parse(retrievedData) : false
  }
  return false
}

const getPersonlizedLastVisitedStorage = () => {
  const currentStorageItems = getPersonlizedStorage()
  if (currentStorageItems) {
    let filteredStorage = currentStorageItems.filter((item) => {
      return item.hasVisited === true && item.timestamp > 0
    })
    if (filteredStorage && filteredStorage.length) {
      filteredStorage.sort((item, item2) => item2.timestamp - item.timestamp)
    }
    return filteredStorage
  }
  return false
}

const checkIfClientVisistedPersonalizedPages = () => {
  const clientVisitHistory = getPersonlizedLastVisitedStorage()
  if (clientVisitHistory && clientVisitHistory.length) {
    return true
  }
  return false
}

module.exports = {
  getClientData: getPersonlizedStorage,
  getClientLastVistData: getPersonlizedLastVisitedStorage,
  checkIfClientVisitedCreditCardPage: checkIfClientVisistedPersonalizedPages
}
