import React from 'react'
import Icon from './Icon'
import classes from './PersonalizedClasses'
import Analytics from '../../../SiteNav/analytics.js'
import { getAdvisorLinkWithCookie } from '../../../../ui/AdvisorQueryParameter/advisors-query-param'

function notifyAnalytics (type, message) {
  // Analytics Tagging
  try {
    const buttonAnalytics = new Analytics()
    buttonAnalytics.ping(message, type)
  } catch (e) {
  }
}

function emailButton (props, size) {
  function handleClick (e) {
    // allow default functionality
    notifyAnalytics('Personalized DAC', 'Email')
  }

  if (props.data.email) {
    return (
      <a href={`mailto:${props.data.email}`} className={classes.actions} onClick={handleClick}>
        <Icon description={'Email'} size={size}>&#xe159;</Icon>
        {'Email'}
      </a>
    )
  }
}

function callButton (props, size) {
  function handleClick (e) {
    // allow default functionality
    notifyAnalytics('Personalized DAC', 'Call')
  }
  if (props.data.phonenumber) {
    return (
      <a href={`tel:${props.data.phonenumber}`} className={classes.actions} onClick={handleClick}>
        <Icon description={'Phone'} size={size}>&#xe0cd;</Icon>
        {'Call'}
      </a>
    )
  }
}

function consultButton (props, size) {
  function handleClick (e) {
    // allow default functionality
    notifyAnalytics('Personalized DAC', 'Request consultation')
  }
  if (props.data.email) {
    const url = props.data.url + '?openFormModal=request-contact'
    const urlWithAuth = getAdvisorLinkWithCookie(url)
    return (
      <a href={urlWithAuth} className={classes.actionsRequest} onClick={handleClick}>
        <Icon description={'Calendar'} size={size}>&#xe616;</Icon>
        {`Request a consultation with ${props.data.name}`}
      </a>
    )
  }
}
const ActionList = (props) => {
  const size = 'u-textSizeMsrp1'

  return (
    <div className={classes.actionGrid}>
      {consultButton(props, size)}
      {emailButton(props, size)}
      {callButton(props, size)}
    </div>
  )
}

export default ActionList
