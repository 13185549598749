import React from 'react'
import xss from 'xss'
import PropTypes from 'prop-types'
import Card from '../Card/Card'

const CardGrid = props => {
  const {
    componentSpacing,
    cardLimit,
    cardList,
    cardGridHeadline,
    cardDescription,
    button
  } = props

  const buildButton = () => {
    const { linkHref, linkTarget, analytics, linkText } = button
    return (
      <a
        className="Button Button--secondary Button--secondary--action-blue Button--small"
        href={linkHref}
        target={linkTarget}
        data-analytics={analytics || ''}
      >
        {linkText}
      </a>
    )
  }

  return (
    <section
      className={`card-grid-component ${componentSpacing}`}
      limit={cardLimit}
    >
      <div
        className="Grid u-sizeConstrained u-sm-flexCol u-sm-flexJustifyCenter"
        data-testid={props.testId || null}
      >
        <div className="u-flex u-flexJustifyBetween">
          {cardGridHeadline && (
            <h2 className="u-textColorDefault u-marginBottom3gu">
              {cardGridHeadline}
            </h2>
          )}
          {button && (
            <div className="Button--customSmallHidden">{buildButton()}</div>
          )}
        </div>
        {cardDescription && (
          <div
            className="card-description Content u-marginBottom4gu"
            dangerouslySetInnerHTML={{ __html: xss(cardDescription) }}
          />
        )}
        <div className="card-list-wrapper u-sm-flex u-sm-flexCol u-marginBottom4gu">
          {cardList.map((card, index) => (
            <Card key={index} card={card} />
          ))}
        </div>
        {button && (
          <div className="u-flex u-flexJustifyCenter Button--customSmallShow u-md-hidden u-lg-hidden">
            {buildButton()}
          </div>
        )}
      </div>
    </section>
  )
}
CardGrid.propTypes = {
  cardDescription: PropTypes.string,
  cardList: PropTypes.array,
  cardLimit: PropTypes.string,
  componentSpacing: PropTypes.string,
  cardGridHeadline: PropTypes.string,
  button: PropTypes.object
}
CardGrid.defaultProps = {
  cardDescription: '',
  cardList: [],
  cardLimit: '3',
  componentSpacing: '',
  cardGridHeadline: '',
  button: null
}
export default CardGrid
