import React, { useState, useEffect } from 'react'
import './index.css'

const CheckboxList = ({ message, error, reference, options, onOptionsSelected }) => {
  const [checkedState, setCheckedState] = useState(options)

  const handleChange = slug => {
    const copyOptions = [...checkedState]
    const modifiedOptions = copyOptions.map(item => {
      if (slug === item.slug) {
        item.checked = !item.checked
      }
      return item
    })
    setCheckedState(modifiedOptions)
  }

  useEffect(() => {
    onOptionsSelected(checkedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedState])

  const currentHideStyle = 'u-invisible'

  return (
    <fieldset className="Form-group">
      <ul className="checkbox-list">
        {error && message && <div className={'Input-errorList u-paddingTop1gu u-marginBottom2gu' + (error ? '' : ' ' + currentHideStyle)}>
          <div className="Input-errorListItem js-formError">{message}</div>
        </div>}
        {options && options.map((option, index) => {
          return (
            <li key={index}>
              <div className="Checkbox">
                <input
                  type="checkbox"
                  className="Checkbox-button checkbox-spacing"
                  id={`custom-checkbox-${option.slug}`}
                  name={option}
                  value={option}
                  ref={reference}
                  onChange={() => handleChange(option.slug)}
                />
                <label className="Checkbox-label" htmlFor={`checked-${option.slug}`}>{option.value}</label>
              </div>
            </li>
          )
        })}
      </ul>
    </fieldset>
  )
}

export default CheckboxList
