import React from 'react'
import PropTypes from 'prop-types'
/*
 *  Generic Components
 */
import StatesData from '../views/usa-states.json'
import InputField from '../views/Input'
import TextAreaField from '../views/Textarea'
import SelectBox from '../views/Selectbox'
import SuccessMessage from '../views/SuccessMessage'
import { ErrorMessageComponent, PleaseNoteComponent, SendEmailButtonComponent } from '../views/StatelessComponent'

/*
 * Helper functions
 */
import { isValidEmail, isValidZipcode } from '../../../common/utils'
import { formSelectedData, CONSTs } from '../views/utils'

/*
 * Contact Brokerage Form component
 */
export default class ContactFormBrokerage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showServiceFailedMessage: false,
      showSuccessMessage: false,
      loading: false,
      formData: {
        firstname: null,
        lastname: null,
        emailaddress: null,
        street: null,
        city: null,
        state: null,
        zipcode: null,
        inquiry: null
      },
      errorFields: {
        firstname: false,
        lastname: false,
        emailaddress: false,
        state: false,
        city: false,
        street: false,
        zipcode: false,
        inquiry: false,
        emailWrongFormat: false
      }
    }
    /*
     * React refs to access the component
     */
    this.firstnameRef = React.createRef()
    this.lastnameRef = React.createRef()
    this.emailaddressRef = React.createRef()
    this.streetRef = React.createRef()
    this.cityRef = React.createRef()
    this.stateRef = React.createRef()
    this.zipcodeRef = React.createRef()
    this.inquiryRef = React.createRef()
    /*
     * Binding this(current object) keyword with the methods
     */
    this.submitForm = this.submitData.bind(this)
    this.changeState = this.changeState.bind(this)
    this.getFormStatus = this.getFormStatus.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
    this.showServerResponse = this.showServerResponse.bind(this)
    this.setEmailErrorMessage = this.setEmailErrorMessage.bind(this)
  }
  /*
   * Show service response
   */
  showServerResponse (props, message) {
    if (props && message) {
      if (message === CONSTs.SUCCESS) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: true,
            showServiceFailedMessage: false
          }
        )
      } else if (message === CONSTs.FAILURE) {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      } else {
        this.setState(
          {
            loading: false,
            showSuccessMessage: false,
            showServiceFailedMessage: true
          }
        )
      }
    }
    return true
  }
  /*
   * Form status: valid or invalid
   */
  getFormStatus () {
    const {
      formData
    } = this.state
    let isFormValid = true
    for (let props in formData) {
      if (
        props !== 'state' &&
        props !== 'city' &&
        props !== 'street' &&
        props !== 'inquiry' &&
        formData[props] === null
      ) {
        isFormValid = false
      }
    }
    return isFormValid
  }

  changeState (args) {
    // To do: Update the data
  }
  /*
   * Render Buttons
   */
  renderSubmitButton () {
    const {
      props: {
        content: {
          formLabels: {
            buttonlabel,
            buttonLoadingLabel
          }
        }
      },
      state: {
        loading
      }
    } = this
    return (
      <SendEmailButtonComponent
        callback={this.submitForm}
        isLoading={loading}
        defaultLabel={buttonlabel}
        loadingLabel={buttonLoadingLabel}
      />
    )
  }
  /*
   * Validate the form fields
   */
  validateField (fieldName, value) {
    // To do: update the newly added fields
    let emailValid = this.state.errorFields.emailaddress
    let firstnameValid = this.state.errorFields.firstname
    let lastnameValid = this.state.errorFields.lastname
    let zipcodeValid = this.state.errorFields.zipcode
    let fieldValues = this.state.formData
    let fieldErrors = this.state.errorFields
    switch (fieldName) {
    case 'firstname':
      firstnameValid = value !== '' || value.length > 0
      fieldValues.firstname = firstnameValid ? value : null
      fieldErrors.firstname = !firstnameValid
      break
    case 'lastname':
      lastnameValid = value !== '' || value.length > 0
      fieldValues.lastname = lastnameValid ? value : null
      fieldErrors.lastname = !lastnameValid
      break
    case 'emailaddress':
      emailValid = isValidEmail(value)
      fieldValues.emailaddress = emailValid ? value : null
      fieldErrors.emailaddress = !emailValid
      fieldErrors.emailWrongFormat = false
      const formEmailAddress = this.emailaddressRef.current.value
      if (formEmailAddress && formEmailAddress.length > 0) {
        fieldErrors.emailWrongFormat = true
      }
      break
    case 'zipcode':
      zipcodeValid = isValidZipcode(value)
      fieldValues.zipcode = zipcodeValid ? value : null
      fieldErrors.zipcode = !zipcodeValid
      break
    default:
      fieldValues[fieldName] = value
      break
    }
    if (fieldName && value) {
      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
  }
  handleFieldChange (event) {
    event.preventDefault()
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    }, () => {
      this.validateField(name, value)
    })
    if (event.keyCode === 13) {
      this.validateField(name, value)
    }
  }
  /*
   * Form Submission
   */
  submitData (event) {
    event.preventDefault()
    const {
      props: { callback, content: { formEmailInfo, formTemplate } },
      state: { formData }
    } = this
    const canFormBeSubmitted = this.getFormStatus()
    if (canFormBeSubmitted === true) {
      // To do: Submit the form
      // Get the data to send email
      this.setState({ loading: true })
      const items = formSelectedData(formData, formTemplate)
      if (callback && typeof callback === 'function' && items) {
        return callback(items, formEmailInfo, this.showServerResponse)
      }
      return true
    } else {
      // errorFields
      const formFirstName = this.firstnameRef.current.value
      const formLastName = this.lastnameRef.current.value
      const formEmailAddress = this.emailaddressRef.current.value
      let formZipCode = this.zipcodeRef.current.value
      let fieldValues = this.state.formData
      let fieldErrors = this.state.errorFields

      // FirstName validation
      if (formFirstName.length < 1) {
        fieldValues.firstname = null
        fieldErrors.firstname = true
      } else {
        fieldValues.firstname = this.state.formData.firstname
        fieldErrors.firstname = false
      }
      // LastName validation
      if (formLastName.length < 1) {
        fieldValues.lastname = null
        fieldErrors.lastname = true
      } else {
        fieldValues.lastname = this.state.formData.lastname
        fieldErrors.lastname = false
      }
      // Email validation
      if (formEmailAddress || !formEmailAddress.length) {
        const cleanedText = formEmailAddress.toLowerCase()
        fieldValues.emailaddress = isValidEmail(cleanedText) ? formEmailAddress : null
        fieldErrors.emailaddress = !fieldValues.emailaddress
      }
      // Zipcode Validation
      if (!formZipCode || formZipCode.length > 0) {
        fieldValues.zipcode = isValidZipcode(formZipCode) ? formZipCode : null
        fieldErrors.zipcode = !isValidZipcode(formZipCode)
      }

      this.setState({
        formData: fieldValues,
        errorFields: fieldErrors
      })
    }
    return false
  }
  /*
   * Set the error message for email address
   */
  setEmailErrorMessage (wrongEmail, message) {
    if (wrongEmail) {
      return message.errorEmailaddressWrongFormat
    } else {
      return message.errorEmailaddress
    }
  }
  render () {
    const {
      props: {
        name,
        content: {
          formErrorMessages,
          formPleaseNote,
          serviceMessages,
          formHeading,
          formLabels
        }
      },
      state: {
        showServiceFailedMessage,
        showSuccessMessage,
        errorFields
      }
    } = this
    return (
      <div className='u-marginTop6gu'>
        {
          showSuccessMessage &&
          serviceMessages &&
          serviceMessages.serviceSuccess &&
          <SuccessMessage
            thankYou={serviceMessages.serviceSuccessThankyou}
            message={serviceMessages.serviceSuccess}
          />
        }
        {
          !showSuccessMessage &&
          <div className='u-sizeConstrained'>
            <form
              name={ name }
              className='u-marginBottom5gu u-size5of12 u-md-size8of12 u-sm-sizeFull'
              onBlur={ (e) => { this.handleFieldChange(e) } }
              onKeyUp={ (e) => { this.handleFieldChange(e) } }
            >
              {
                formHeading &&
                formHeading.title &&
                <h2 className='u-marginBottom5gu'>{ formHeading.title }</h2>
              }
              <InputField
                name='firstname'
                id='firstname'
                placeholder=''
                label={formLabels.firstname}
                reference={this.firstnameRef}
                error={errorFields.firstname}
                message={formErrorMessages.errorFirstname}
              />
              <InputField
                name='lastname'
                id='lastname'
                placeholder=''
                label={formLabels.lastname}
                reference={this.lastnameRef}
                error={errorFields.lastname}
                message={formErrorMessages.errorLastname}
              />
              <InputField
                name='emailaddress'
                id='email'
                placeholder=''
                label={formLabels.emailaddress}
                reference={this.emailaddressRef}
                error={errorFields.emailaddress}
                message={ this.setEmailErrorMessage(errorFields.emailWrongFormat, formErrorMessages) }
              />
              <InputField
                name='street'
                id='street'
                placeholder=''
                label={formLabels.street}
                reference={this.streetRef}
              />
              <InputField
                name='city'
                id='city'
                placeholder=''
                label={formLabels.city}
                reference={this.cityRef}
              />
              <SelectBox
                name='state'
                id='state'
                placeholder=''
                label={formLabels.state}
                reference={this.stateRef}
                callback={this.changeState}
                data={StatesData}
              />
              <InputField
                name='zipcode'
                id='zipcode'
                placeholder=''
                label={formLabels.zipcode}
                reference={this.zipcodeRef}
                error={errorFields.zipcode}
                message={formErrorMessages.errorZipcode}
              />
              <TextAreaField
                name='inquiry'
                id='inquiry'
                placeholder=''
                label={formLabels.inquiry}
                reference={this.inquiryRef}
                row={3}
              />
              {
                showServiceFailedMessage &&
                serviceMessages.serviceFailed &&
                <ErrorMessageComponent
                  message={serviceMessages.serviceFailed}
                />
              }
              {
                this.renderSubmitButton()
              }
            </form>
            {
              formPleaseNote &&
              formPleaseNote.message &&
              <PleaseNoteComponent
                content={formPleaseNote.message}
              />
            }
          </div>

        }
      </div>
    )
  }
}

/*
 * Form default Proptypes
 */
const propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.object
}

ContactFormBrokerage.propTypes = propTypes
