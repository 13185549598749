import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tab from './Tabs.jsx'
import TabPanel from './TabPanel.jsx'

const KEYCODE = {
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  HOME: 36,
  END: 35
}

export default class TabList extends Component {
  static propTypes = {
    tabs: PropTypes.oneOfType([
      PropTypes.instanceOf(Array).isRequired,
      PropTypes.string.isRequired
    ]),
    panels: PropTypes.oneOfType([
      PropTypes.instanceOf(Array).isRequired,
      PropTypes.string.isRequired
    ])
  };

  constructor (props) {
    super(props)

    this.state = {
      activeIndex: 0
    }

    // Bind event handlers
    this.onClickTabItem = this.onClickTabItem.bind(this)
    this.onPressKey = this.onPressKey.bind(this)
  }

  // Getters

  get tabLength () {
    // return number
    return this.props.tabs.length
  }

  // Event Handlers

  onClickTabItem (index) {
    // set the active tab: returns [number]
    this.setState({ activeIndex: index })
  }

  onPressKey (key, index) {
    // handle navigation using arrow keys: returns [number]
    let newIndex
    switch (key) {
    case KEYCODE.LEFT:
    case KEYCODE.UP:
      newIndex = (index - 1 + this.tabLength) % this.tabLength
      break
    case KEYCODE.RIGHT:
    case KEYCODE.DOWN:
      newIndex = (index + 1) % this.tabLength
      break
    case KEYCODE.HOME:
      newIndex = 0
      break
    case KEYCODE.END:
      newIndex = this.tabLength - 1
      break
    default:
      return
    }

    this.setState({ activeIndex: newIndex })
  }

  // Lifecycle Hooks

  // Build Panels and Tabs

  panelContainer (panels) {
    return panels.map((panel, idx) => {
      const isActive = this.state.activeIndex === idx
      return <TabPanel key={idx} panel={panel} isActive={isActive} />
    })
  }

  tabListContainer (tabs) {
    return Array.isArray(tabs) && tabs.length > 1 ? (
      <div className="tabbedContent-tabList u-sm-hidden">
        <ol className="tabList u-borderBottom u-paddingHoriz0" role="tablist">
          {tabs.map((tab, idx) => {
            return (
              <Tab
                isActive={this.state.activeIndex === idx}
                key={idx}
                index={idx}
                label={tab.title}
                onClick={this.onClickTabItem}
                onKeyPress={this.onPressKey}
              />
            )
          })}
        </ol>
      </div>
    ) : (
      ''
    )
  }

  // Render

  render () {
    let { tabs, panels } = this.props

    /** Panel Array Enforcer
     *  @note: a single panel will come back as a string. Jam it in an array, if so.
     */
    const panelArray = Array.isArray(panels) ? panels : [panels]

    return (
      <div className="tabbedContent-container">
        {this.tabListContainer(tabs)}
        <div className="tabbedContent-panels">
          {this.panelContainer(panelArray)}
        </div>
      </div>
    )
  }
}
