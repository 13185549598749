import React from 'react'
import PropTypes from 'prop-types'

const Spacer = props => {
  const { spacing, children, testId } = props
  const classList = []

  switch (spacing) {
  case 'bottom':
    classList.push('u-sm-marginBottom7gu')
    classList.push('u-marginBottom10gu')
    break
  case 'top':
    classList.push('u-sm-marginTop7gu')
    classList.push('u-marginTop10gu')
    break
  case 'full':
    classList.push('u-sm-marginVert7gu')
    classList.push('u-marginVert10gu')
    break
  case 'nospace':
    break
  }

  if (classList.length === 0) {
    return children
  }

  return (
    <div
      className={classList.join(' ')}
      data-testid={testId ? `${testId}-wrapper` : null}
    >
      {children}
    </div>
  )
}

Spacer.propTypes = {
  spacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace']),
  children: PropTypes.node.isRequired,
  testId: PropTypes.string
}

export default Spacer
