import moment from 'moment'
import 'moment-timezone'

/*
 *  Set Dom
 */
export const setInnerHtml = (args) => {
  return (
    { __html: args }
  )
}

/*
 *  Static Text
 */
export const CONSTs = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  CROSSDOMAIN: 'cross domain',
  DISCLAIMERS: {
    'prevClose': 'BeforeMarketOpen',
    'preDividend': 'PreDividend',
    'exDividend': 'ExDividend'
  },
  TESTDOMAIN: 'https://www.qa.ameriprise.com'
}

/*
 *  Service API Params
 */
export const SERVICEAPI = {
  CONSUMERID: 'id=A0163796-61J0RSA3VPQ39ANK;type=WEB',
  URL: '/ods.svc/getMarketQuotes',
  QUALITYOFSERVICE: 'delayed'
}

export const DATE_FORMATS = {
  ARTICLE: 'M/D/YYYY h:mm a [ET]',
  API: 'YYYY-MM-DD'
}

export const TIMEZONES = {
  ARTICLE: 'America/New_York'
}

export const convertDateString = (str, format, timezone) => {
  // if no `str`, return empty string
  if (!str) return ''

  // Checks to see if the timestamp is proper UTC
  // TODO Change this if we consistently get back UTC at a later date
  const formattedStr = str.slice(-1) === 'Z' ? str : `${str}Z`

  const formattedDate = moment(formattedStr, moment.ISO_8601)

  // If invalid date, return empty string
  if (!formattedDate.isValid()) return ''

  // If no timezone is supplied assume UTC time
  const timeZonedDate = timezone ? formattedDate.tz(timezone) : formattedDate.utc()

  // format and convert the am/pm to a.m./p.m.
  return timeZonedDate.format(format).replace('am', 'a.m.').replace('pm', 'p.m.')
}

export const parseValue = value => value && parseFloat(value).toFixed(2)

export const TIMEDATA = {
  TIMEZONE: 'America/New_York',
  FORMAT: 'MM/DD/YYYY hh:mm A z'
}

export const formatDateTime = (str) => {
  const formatTimezone = str.includes('EDT')
    ? str.replace('EDT', 'ET')
    : str.includes('EST') ? str.replace('EST', 'ET') : str
  return formatTimezone
}

export const setTimeStamp = (data) => {
  const { TIMEZONE, FORMAT } = TIMEDATA
  const times = data.map(index => (moment(index.quoteInfo.lstTrdTm, moment.ISO_8601)))
  // If more indices are added, you will have to manually add the extra times as arguments to moment.max
  if (times && times.length > 1) {
    const lastTime = moment.min(times[0], times[1], times[2]).tz(TIMEZONE).format(FORMAT)
    return formatDateTime(lastTime)
  } else {
    return ''
  }
}
