import React, { Component } from 'react'

/*
 * Helper methods
 */
import { setInnerHtml } from './utils'

/*
 *  <SuccessMessage
 *  message='Thank you, we have received your request' //Success message to be passed on to display
 *  />
 */

/*
 * Success Message Component
 */
// Component should be in class when we are using Refs
class SuccessModal extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)

    this.dataQaSuccess = 'Message--success'
    this.successMessageRef = React.createRef()
    this.hide = this.hide.bind(this)
  }
  componentDidMount () {
    if (this.successMessageRef) {
      const scrollToY = this.successMessageRef.current.offsetTop
      this.successMessageRef.current.focus()
      window.scrollTo(0, scrollToY)
    }
  }
  hide () {
    this.props.parentForm.hide()
  }
  render () {
    const { thankYou, message, dataAnalytics, parentForm } = this.props

    const dataAnalyticsMsg = dataAnalytics || this.dataQaSuccess

    return (
      <div class="u-marginTop2gu u-sizeConstrained" role="alert">
        <div class="Notification--withIcon Notification--alert Notification--confirm u-marginBottom2gu u-padding2gu ">
          <h3
            id="modalTitle"
            className="Notification-content js-formNotification"
            data-qa={this.dataQaSuccess}
            data-analytics={dataAnalyticsMsg}
            data-icon={'\u2713'}
          >
            {thankYou}
            <button
              class="Modal-icon Modal-icon--inline u-paddingLeft4gu"
              onClick={this.hide}
            >
              <span class="u-hiddenVisually">Close this dialog</span>
            </button>
          </h3>
          <p
            id="modalDesc"
            className="u-padding3gu"
            data-qa={this.dataQaSuccess}
            dangerouslySetInnerHTML={setInnerHtml(message)}
            ref={this.successMessageRef}
          />
          {parentForm && (
            <button
              className="Button Button--primary"
              role="button"
              type="button"
              onClick={this.hide}
            >
              OK
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default SuccessModal
