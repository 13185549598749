import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import xss from 'xss'
import FeatureFlag from './FeatureFlag'

const CardContent = props => {
  const {
    cardDescription,
    cardImage,
    heading,
    superHeadline,
    readTime,
    featureFlag,
    author,
    date
  } = props

  const [imagePath, setImagePath] = useState('')

  useEffect(() => {
    if (cardImage) {
      setImagePath(cardImage)
      return
    }
    setImagePath(`${window.AMPCOMPATH}images/card-default-image.png`)
  }, [cardImage])

  const getCardDetails = () => {
    if (!author && !readTime && !date) {
      return null
    }
    const details = [author, date, readTime].filter(item => !!item)
    return (
      <div className="card-bottom u-sizeFull u-paddingHoriz4gu">
        <p className="u-textColorLight u-textSizeMsrm1">
          {details.reduce((prev, curr) => (
            <>
              {prev}
              <span className="u-marginHoriz1gu">&bull;</span>
              {curr}
            </>
          ))}
        </p>
      </div>
    )
  }

  return (
    <>
      {featureFlag && (
        <FeatureFlag
          backgroundColor={featureFlag.backgroundColor}
          textColor={featureFlag.textColor}
          label={featureFlag.label}
        />
      )}
      <div className="Card-header Card-headerBackgroundImage Card--redesign card-image">
        <img src={imagePath} alt="" />
      </div>
      <div className="Card-body card-content u-paddingHoriz4gu">
        {superHeadline && (
          <h5 className="u-textColorLight u-textSizeMsrm1 u-textUppercase">
            {superHeadline}
          </h5>
        )}
        <h4 className="card-heading u-paddingVert2gu u-textColorNeutralGrey">
          <strong>{heading}</strong>
        </h4>
        <div
          className="card-description"
          dangerouslySetInnerHTML={{ __html: xss(cardDescription) }}
        />
      </div>
      {getCardDetails()}
    </>
  )
}
CardContent.propTypes = {
  cardDescription: PropTypes.string,
  cardImage: PropTypes.string,
  heading: PropTypes.string,
  superHeadline: PropTypes.string,
  readTime: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  featureFlag: PropTypes.object
}
export default CardContent
