// eslint-disable-next-line no-unexpected-multiline
(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const hyperLinks = document.getElementsByTagName('a')
    hyperLinks.forEach(element => {
      const targetElement = element.getAttribute('href')
      /* /external/interstitial find the list of target element */
      if (targetElement && targetElement !== null) {
        const interstitialLink = /external\/interstitial/g
        const interstitialLinksFound = targetElement.match(interstitialLink)
        if (interstitialLinksFound && interstitialLinksFound.length > 0) {
          element.addEventListener('click', (event) => {
            event.preventDefault()
            window.ampInterstitialWindowReference = window.open()
            window.ampInterstitialWindowReference.location = targetElement
          })
        }
      }
    })
  })
})()
