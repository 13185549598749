import React, { Component } from 'react'
import jsCookie from 'js-cookie'

export default class LogoutMessage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)
    this.logoutMessageRef = React.createRef()
    this.lastAmpAuthState = jsCookie.get('lastAmpAuthState')
    this.loggedIn = jsCookie.get('amp-wls')
  }

  render () {
    if (!(this.lastAmpAuthState === 'active' && this.loggedIn === 'false')) {
      return null
    }

    if (this.props.content.message === undefined) {
      return null
    }

    return (
      <div className='u-paddingHoriz2gu u-paddingTop2gu'>
        <div role='alert'>
          <div className='Notification Notification--withIcon Notification--alert Notification--confirm'>
            <div ref={this.logoutMessageRef} className='Notification-content js-formNotification' data-icon={'\u2713'} dangerouslySetInnerHTML={{ __html: this.props.content.message }}></div>
          </div>
        </div>
      </div>
    )
  }
}
