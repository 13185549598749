import React from 'react'
import PropTypes from 'prop-types'
import './categories.less'

const Categories = props => {
  const { content, testId } = props

  const classNames = [
    'Categories',
    'u-posRelative',
    'u-paddingVert10gu',
    'u-md-paddingHoriz3gu'
  ]
  switch (content.bgColor) {
  case 'purple-tint':
    classNames.push('u-bgColorPurpleLight')
    break
  case 'mint-tint':
    classNames.push('u-bgColorMintLight')
    break
  case 'lime-tint':
    classNames.push('u-bgColorLimeLight')
    break
  case 'stone':
    classNames.push('u-bgColorStone')
    break
  default:
    classNames.push('u-bgColorWhite')
  }

  const renderHeading = (block, testId) => {
    if (block.url) {
      return (
        <h4
          data-testid={testId ? `${testId}-title` : null}
          className="u-textBold u-marginBottom4gu"
        >
          <a
            href={block.url}
            target={block.linkTarget}
            data-analytics={block.analytics}
            dangerouslySetInnerHTML={{ __html: block.title }}
          ></a>
        </h4>
      )
    }

    if (!block.title) {
      return null
    }

    return (
      <h4
        data-testid={testId ? `${testId}-title` : null}
        className="u-textBold u-marginBottom4gu"
        dangerouslySetInnerHTML={{ __html: block.title }}
      ></h4>
    )
  }

  const renderBlocks = (blocks, testId) => {
    const columnWidth =
      content.columnLayout === 'three-column'
        ? 'u-size1of3'
        : 'u-size1of4 u-md-size1of2'
    return (
      <div className="Grid Grid--withGutter">
        {blocks.map((block, index) => (
          <div
            className={`Category u-marginTop10gu u-flex u-flexCol u-sm-sizeFull ${columnWidth}`}
            data-testid={testId ? `${testId}-Category-Container` : null}
            key={index}
          >
            <div className="Content u-textLeft">
              {block.iconsDropdown && (
                <svg
                  aria-hidden="true"
                  width="64"
                  height="64"
                  preserveAspectRatio="xMinYMin meet"
                  className={`u-marginBottom4gu ${
                    block.iconsColor ? block.iconsColor : 'u-fillColorDarkMint'
                  }`}
                  data-testid={testId ? `${testId}-icon` : null}
                >
                  <use
                    href={`${window.AMPCOMPATH}images/brand-icons/icon-sprite.svg#${block.iconsDropdown}`}
                  />
                </svg>
              )}
              {block.image && !block.iconsDropdown && (
                <div
                  className="u-bgSizeCover card-image u-marginBottom4gu"
                  style={{ backgroundImage: `url(${block.image})` }}
                  data-testid={testId ? `${testId}-image-${index}` : null}
                ></div>
              )}
              {renderHeading(block, testId)}
              <div dangerouslySetInnerHTML={{ __html: block.content }}></div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <section
      className={classNames.join(' ')}
      data-testid={testId ? `${testId}-section` : null}
    >
      <div className="u-sizeConstrained u-sm-flexJustifyCenter">
        <div className="Content">
          <h3
            data-testid={testId ? `${testId}-headline` : null}
            dangerouslySetInnerHTML={{ __html: content.headline }}
          ></h3>
          {content.description && (
            <div
              dangerouslySetInnerHTML={{ __html: content.description }}
            ></div>
          )}
        </div>
        {renderBlocks(content.block, testId)}
      </div>
    </section>
  )
}

Categories.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    headline: PropTypes.string,
    block: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        image: PropTypes.string,
        iconsDropdown: PropTypes.string,
        iconsColor: PropTypes.string,
        url: PropTypes.string,
        linkTarget: PropTypes.string,
        analytics: PropTypes.string,
        title: PropTypes.string
      })
    ),
    bgColor: PropTypes.oneOf([
      'purple-tint',
      'mint-tint',
      'lime-tint',
      'stone',
      'white'
    ]),
    columnLayout: PropTypes.oneOf(['three-column', 'four-column'])
  }),
  testId: PropTypes.string
}

export default Categories
