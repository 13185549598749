import { CardCarousel } from '../CardCarousel/index'

document.querySelectorAll('section.amp-carousel-component').forEach((carousel) => {
  const cardCarousel = new CardCarousel(carousel, { loop: true, paginate: true, tabbing: true })
  cardCarousel.init()

  document.querySelector('.swiper-button-prev').addEventListener('click', () => {
    const activeSlide = document.querySelector('.swiper-slide.active')
    const prevSlide = activeSlide.nextElementSibling
    if (prevSlide) {
      prevSlide.setAttribute('hidden', '')
    } else {
      activeSlide.parentElement.children.forEach(child => {
        child.removeAttribute('hidden')
      })
    }
  })

  document.querySelector('.swiper-button-next').addEventListener('click', () => {
    const activeSlide = document.querySelector('.swiper-slide.active')
    activeSlide.removeAttribute('hidden')
  })
})
