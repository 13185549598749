import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import '../views/index.css'

/*
 * SelectBox Component
 *   <SelectBox
 *      name='state'
 *      id='state'
 *      placeholder='State'
 *      label='State'
 *      reference={this.stateRef} //React Refs
 *      callback={this.changeState} //Callback after the select value changed
 *      data={StatesData} //Data to load the value in the selectbox
 *   />
 */

class SelectBox extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)
    this.state = {
      pickedState: null
    }

    this.selectedItem = this.selectedItem.bind(this)
    this.renderItems = this.renderItems.bind(this)
    this.dataQa = 'Selectbox-' + props.id
  }
  selectedItem (event) {
    const { callback } = this.props
    const selectedItem = event.target.value
    this.setState({ pickedState: selectedItem }, () => {
      return callback(selectedItem, this.state)
    })
  }
  renderItems () {
    const {
      props: { name, id, data, reference },
      state: { pickedState }
    } = this

    return (
      <select
        value={pickedState || ''}
        className="Dropdown-menu"
        data-qa={this.dataQa}
        id={id}
        name={name}
        ref={reference}
        onChange={this.selectedItem}
      >
        <option key="item--0" value="">
          Select State
        </option>
        {data &&
          data.length > 0 &&
          data.map(item => (
            <option key={'item-' + item.key} value={item.key}>
              {item.value}
            </option>
          ))}
      </select>
    )
  }
  render () {
    const { id, label, error, message, required } = this.props
    return (
      <Fragment>
        {/* TODO: We need to be able to reference the ID in this return... */}
        <label
          className={'Dropdown-label ' + (required ? 'required' : '')}
          htmlFor={id}
        >
          {label}
        </label>
        <div
          className={classnames(`Dropdown ${!error && 'u-marginBottom5gu'}`, {
            'Dropdown--error': error === true
          })}
        >
          {this.renderItems()}
        </div>
        {error && (
          <ul className="Input-errorList u-paddingTop1gu u-marginBottom2gu">
            <li
              className="Input-errorListItem js-formError"
              data-qa={'SelectError-' + id}
            >
              {' '}
              {message}
            </li>
          </ul>
        )}
      </Fragment>
    )
  }
}

export default SelectBox
