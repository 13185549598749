import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const SkipLink = props => {
  const {
    id,
    href,
    index,
    targetSelector,
    focusSelector,
    label,
    shouldFocusOnMount
  } = props

  const ref = useRef(null)

  useEffect(() => {
    if (shouldFocusOnMount && ref && ref.current) {
      ref.current.focus()
    }
  }, [shouldFocusOnMount])

  const handleLinkClick = (event, targetQuerySelector, focusQuerySelector) => {
    if (!targetQuerySelector) {
      return
    }
    event.preventDefault()
    try {
      event.target.blur()
      const targetElement = document.querySelector(targetQuerySelector)
      targetElement && targetElement.scrollIntoView({ behavior: 'smooth' })
      if (focusQuerySelector && typeof focusQuerySelector === 'function') {
        focusQuerySelector()
      } else {
        const focusElement = document.querySelector(
          focusQuerySelector || targetQuerySelector
        )
        focusElement && focusElement.focus()
      }
    } catch (e) {}
  }

  return (
    <a
      id={id}
      href={href}
      ref={ref}
      className="Link Link--skip"
      tabIndex={index + 1}
      onClick={event => handleLinkClick(event, targetSelector, focusSelector)}
      key={label}
    >
      {label}
    </a>
  )
}
SkipLink.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  index: PropTypes.number,
  targetSelector: PropTypes.string,
  focusSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  label: PropTypes.string,
  shouldFocusOnMount: PropTypes.bool
}
export default SkipLink
