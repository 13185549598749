import React from 'react'
import xss from 'xss'
import './hero-section-front.less'
import PropTypes from 'prop-types'
import Spacer from '../Spacer'

const HeroTopicFront = props => {
  const { content, testId } = props
  const {
    heading,
    body,
    image,
    imageMobile,
    textAlignment,
    facetColor,
    hideMobileImage,
    componentSpacing
  } = content

  const showMobileImage = hideMobileImage !== 'true'
  const largeFacet = `${window.AMPCOMPATH}images/facets/hero-facets-${
    facetColor || 'midnight-blue'
  }.svg`
  const mobileFacet = `${window.AMPCOMPATH}images/facets/hero-facets-mobile-${
    facetColor || 'midnight-blue'
  }.svg`

  return (
    <Spacer spacing={componentSpacing}>
      <section className="HeroSectionFront" data-analytics-container="Hero">
        <img
          className="HeroSectionFront-facet u-sm-hidden"
          src={largeFacet}
          alt=""
          data-testid={testId ? `${testId}-facet` : null}
        />
        <img
          className="HeroSectionFront-facet u-md-hidden u-lg-hidden"
          src={mobileFacet}
          alt=""
          data-testid={testId ? `${testId}-facet-mobile` : null}
        />

        <div className="HeroSectionFront-content">
          <div className="HeroSectionFront-heading u-sizeConstrained">
            {heading && (
              <h1
                className={`Type-Display u-paddingTop10gu ${textAlignment}`}
                dangerouslySetInnerHTML={{ __html: xss(heading) }}
                data-testid={testId ? `${testId}-heading` : null}
              ></h1>
            )}
            {body && (
              <div
                className={`Content Content--large u-paddingTop2gu ${textAlignment}`}
                dangerouslySetInnerHTML={{ __html: xss(body) }}
                data-testid={testId ? `${testId}-body` : null}
              ></div>
            )}
          </div>
          <div className="u-paddingBottom10gu u-sm-paddingHoriz3gu u-paddingTop10gu u-posRelative">
            {image && (
              <img
                className="HeroSectionFront-featuredImage u-sm-hidden u-block u-md-sizeFull u-sizeConstrained"
                src={image}
                alt=""
                data-testid={testId ? `${testId}-image` : null}
              />
            )}
            {showMobileImage && (
              <img
                className="HeroSectionFront-featuredImage u-md-hidden u-lg-hidden u-block u-sizeConstrained"
                src={imageMobile}
                alt=""
                data-testid={testId ? `${testId}-image-mobile` : null}
              />
            )}
          </div>
        </div>
      </section>
    </Spacer>
  )
}

HeroTopicFront.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.string,
    imageMobile: PropTypes.string,
    textAlignment: PropTypes.string,
    facetColor: PropTypes.oneOf([
      'midnight-blue',
      'light-blue',
      'purple',
      'berry',
      'mint',
      'dark-mint',
      'orange',
      'dark-grey'
    ]),
    hideMobileImage: PropTypes.string,
    componentSpacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace'])
  }),
  testId: PropTypes.string
}

export default HeroTopicFront
