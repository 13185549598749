import React from 'react'
import PropTypes from 'prop-types'

const FeatureFlag = props => {
  const { backgroundColor, textColor, label, testId } = props

  const getBackgroundColor = () => {
    if (backgroundColor === 'berry') {
      return 'u-bgColorBerry'
    }
    return 'u-bgColorLime'
  }
  return (
    <div
      className={`Chip--ribbon Card--featureFlag ${getBackgroundColor()}`}
      data-testid={testId || null}
    >
      <h6 className={`${textColor} u-textBold u-textUppercase`}>{label}</h6>
    </div>
  )
}
FeatureFlag.propTypes = {
  backgroundColor: PropTypes.oneOf(['berry', 'lime-light']),
  textColor: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string
}
export default FeatureFlag
